import React, { useCallback, useContext, useEffect, useState } from "react";
import { getPackages } from "../../api/packages/Packages";
import { LanguageContext } from "../../context/LanguageContext";
import Package from "./Package";

export default ({ title }) => {
  const [packages, setPackages] = useState();

  const { language } = useContext(LanguageContext);

  const loadPackages = useCallback(async () => {
    const response = await getPackages(language);
    setPackages(response);
  }, [language]);

  useEffect(() => {
    loadPackages();
  }, [loadPackages]);

  return (
    <section id="pricing-table" className="spacing">
      <div className="container" style={{ maxWidth: "1440px" }}>
        {title && (
          <div class="row">
            <div class="col-md-12">
              <div class="text-center mb-5">
                <h3>De pakketten</h3>
                <p>Kies het pakket dat pas bij jou</p>
              </div>
            </div>
          </div>
        )}
        <div className="card-deck" style={{ justifyContent: "center" }}>
          {typeof packages === "object" &&
            packages
              ?.sort((p1, p2) => p1.position - p2.position)
              .map((p) => <Package key={p.package_uuid} pakket={p} />)}
        </div>
      </div>
    </section>
  );
};
