import React, { useEffect } from "react";
import Team from "../components/about/Team";
import Start from "../components/contact/Start";
import Questions from "../components/home/Questions";
import Hero from "../components/verhuren/Hero";
import Huren from "../components/verhuren/Huren";
import Steps from "../components/verhuren/Steps";

export default () => {
  const image = require("../assets/images/over-ons_hero--bg--2.jpg");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Hero
        title="Waarom verhuren via LTWonen?"
        linktext="Of laat ons je adviseren"
        link="verhuren-contact"
        image={image}
      />
      <Huren title />
      <Steps />
      <Team />
      <Questions blue />
      <Start />
    </div>
  );
};
