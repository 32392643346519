import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  selectFilters,
  setSelectedFilters,
} from "../../features/houses/housesReducer";

export default ({
  amount,
  onMinChange,
  onMaxChange,
  onTypeChange,
  selectedType,
}) => {
  const filters = useSelector(selectFilters);
  const dispatch = useDispatch();

  return (
    <div className="sidebar-widget search-filter-widget">
      <h4>{`${amount || 0} Resultaten`}</h4>
      <ul className="tag-menu">
        <li
          className={selectedType === "list" && "active"}
          onClick={() => onTypeChange("list")}
        >
          <Link>Lijst</Link>
        </li>
        {/* <li
          className={selectedType === "map" && "active"}
          onClick={() => onTypeChange("map")}
        >
          <Link>Kaart</Link>
        </li> */}
      </ul>
      <form action="" className="form-widget">
        <h5>Prijs</h5>

        <select
          name="minimum"
          className="select_menu"
          onChange={(e) =>
            dispatch(
              setSelectedFilters({ ...filters, minimum: e.target.value })
            )
          }
          value={filters?.minimum}
        >
          {[
            { value: 0, name: "Geen minimum" },
            { value: 100, name: "€100" },
            { value: 200, name: "€200" },
            { value: 300, name: "€300" },
            { value: 400, name: "€400" },
            { value: 500, name: "€500" },
            { value: 600, name: "€600" },
            { value: 700, name: "€700" },
            { value: 800, name: "€800" },
            { value: 900, name: "€900" },
            { value: 1000, name: "€1000" },
            { value: 1100, name: "€1100" },
            { value: 1200, name: "€1200" },
            { value: 1300, name: "€1300" },
            { value: 1400, name: "€1400" },
            { value: 1500, name: "€1500" },
            { value: 1600, name: "€1600" },
            { value: 1700, name: "€1700" },
            { value: 1800, name: "€1800" },
            { value: 1900, name: "€1900" },
            { value: 2000, name: "€2000" },
            { value: 2100, name: "€2100" },
            { value: 2200, name: "€2200" },
            { value: 2300, name: "€2300" },
            { value: 2400, name: "€2400" },
          ].map((option) => (
            <option
              key={option.value}
              value={option.value}
            >{`Vanaf ${option.name}`}</option>
          ))}
        </select>

        <select
          name="maximum"
          className="select_menu"
          onChange={(e) =>
            dispatch(
              setSelectedFilters({ ...filters, maximum: e.target.value })
            )
          }
          value={filters?.maximum}
        >
          {[
            { value: 300, name: "€300" },
            { value: 400, name: "€400" },
            { value: 500, name: "€500" },
            { value: 600, name: "€600" },
            { value: 700, name: "€700" },
            { value: 800, name: "€800" },
            { value: 900, name: "€900" },
            { value: 1000, name: "€1000" },
            { value: 1100, name: "€1100" },
            { value: 1200, name: "€1200" },
            { value: 1300, name: "€1300" },
            { value: 1400, name: "€1400" },
            { value: 1500, name: "€1500" },
            { value: 1600, name: "€1600" },
            { value: 1700, name: "€1700" },
            { value: 1800, name: "€1800" },
            { value: 1900, name: "€1900" },
            { value: 2000, name: "€2000" },
            { value: 2100, name: "€2100" },
            { value: 2200, name: "€2200" },
            { value: 2300, name: "€2300" },
            { value: 2400, name: "€2400" },
            { value: 2500, name: "€2500" },
            { value: 99999, name: "Geen maximum" },
          ].map((option) => (
            <option
              key={option.value}
              value={option.value}
            >{`Tot ${option.name}`}</option>
          ))}
        </select>
      </form>
    </div>
  );
};
