import React, { useEffect } from "react";
import GoTo from "../components/pageNotFound/GoTo";
import Hero from "../components/verhuren/Hero";

export default ({ onOpen }) => {
  const image = require("../assets/images/contact-bg.jpg");

  onOpen();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Hero
        title={
          <p>
            404
            <br />
            Er ging iets
            <br />
            verkeerd!
          </p>
        }
        image={image}
      />
      <GoTo />
    </div>
  );
};
