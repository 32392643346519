import React from "react";
import USP from "./USP";

export default ({
  backgroundColor,
  whiteIcons,
  iconColor,
  iconBackgroundColor,
  textColor,
  usps,
}) => {
  return (
    <section id="huren" className="spacing" style={{ backgroundColor }}>
      <div className="container">
        <h3 className="text-center mb-5" style={{ color: textColor }}>
          Huren via LTWonen
        </h3>

        <div className="row">
          {usps?.map((usp, index) => (
            <USP
              key={usp.title_nl + index}
              whiteIcons={whiteIcons}
              color={iconColor}
              backgroundColor={iconBackgroundColor}
              textColor={textColor}
              title={usp.title_nl || usp.title_eng || usp.title_de}
              text={usp.text_nl || usp.text_eng || usp.text_de}
              icon={`${process.env.REACT_APP_BASE_URL}${usp.icon}`}
            />
          ))}
        </div>
      </div>
    </section>
  );
};
