import React, { useCallback, useContext, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { getHouse } from "../api/houses/Houses";
import Images from "../components/detail/Images";
import Properties from "../components/detail/Properties";
import SearchArea from "../components/detail/SearchArea";
import Questions from "../components/home/Questions";
import Subscribe from "../components/home/Subscribe";
import { LanguageContext } from "../context/LanguageContext";

export default () => {
  const { zipcode, house_number, appartment } = useParams();

  const [details, setDetails] = useState();

  const { language } = useContext(LanguageContext);

  const getDetails = useCallback(async () => {
    const result = await getHouse(zipcode, house_number, appartment, language);
    setDetails(result);
  }, [zipcode, house_number, appartment, language]);

  useEffect(() => {
    getDetails();
    window.scrollTo(0, 0);
  }, [getDetails]);

  return (
    <div>
      <section id="page-banner" className="spacing aanbod-page">
        <div className="container" />
      </section>
      <SearchArea details={details} />
      <section id="page-content" className="spacing pb-4">
        <div className="container">
          <Images
            images={
              details?.house_images && [
                `${process.env.REACT_APP_BASE_URL}${details?.preview_image}`,
                ...details?.house_images?.map(
                  (image) => `${process.env.REACT_APP_BASE_URL}${image.image}`
                ),
              ]
            }
          />
          <Properties details={details} />
        </div>
      </section>
      <Questions backgroundColor="#F7F7F8" />
      <Subscribe />
    </div>
  );
};
