import React, { useEffect, useState } from "react";

import Offer from "./pages/Offer";
import Contact from "./pages/Contact";
import Detail from "./pages/Detail";
import Verhuren from "./pages/Verhuren";
import Packages from "./pages/Packages";
import VerhurenAbout from "./pages/VerhurenAbout";
import VerhurenContact from "./pages/VerhurenContact";
import PageNotFound from "./pages/PageNotFound";
import Beheer from "./pages/Beheer";
import Header from "./components/Header";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import OrderPackage from "./pages/OrderPackage";
import TOS from "./pages/TOS";
import { getBrokenLinks } from "./api/pages/Pages";

import { LanguageContext } from "./context/LanguageContext";
import CookieConsent from "react-cookie-consent";
import TagManager from "react-gtm-module";

function App() {
  const tagManagerArgs = {
    gtmId: "GTM-T8WK4X2",
  };

  TagManager.initialize(tagManagerArgs);
  const containsVerhuren = () => {
    return window.location.pathname.includes("verhuren");
  };

  const [verhuren, setVerhuren] = useState(containsVerhuren());
  const [language, setLanguage] = useState("nl");

  const [brokenLinks, setBrokenLinks] = useState();

  useEffect(() => {
    const getBroken = async () => {
      const links = await getBrokenLinks();
      setBrokenLinks(links);
    };
    getBroken();
  }, []);

  return (
    <div className={verhuren ? "skyblue__layout" : ""}>
      <LanguageContext.Provider
        value={{ language, toggleLanguage: setLanguage }}
      >
        <Router>
          <Header onVerhurenChange={setVerhuren} verhuren={verhuren} />
          <Switch>
            {brokenLinks?.map((brokenLink) => (
              <Route path={brokenLink.old_url} exact>
                <Redirect to={brokenLink.redirect_to_url} />
              </Route>
            ))}
            <Route path="/algemene-voorwaarden" exact>
              <TOS />
            </Route>
            <Route path="/over-ons" exact>
              <About />
            </Route>
            <Route
              path="/pakket/:id/:extra/:technical/:financial/:energy"
              exact
            >
              <OrderPackage />
            </Route>
            <Route path="/aanbod" exact>
              <Offer />
            </Route>
            <Route path="/contact" exact>
              <Contact />
            </Route>
            <Route path="/detail/:zipcode/:house_number" exact>
              <Detail />
            </Route>
            <Route path="/detail/:zipcode/:house_number/:appartment" exact>
              <Detail />
            </Route>
            <Route path="/verhuren">
              <Verhuren />
            </Route>
            <Route path="/verhuren-pakketten">
              <Packages />
            </Route>
            <Route path="/verhuren-over-ons">
              <VerhurenAbout />
            </Route>
            <Route path="/verhuren-contact">
              <VerhurenContact />
            </Route>
            <Route path="/beheer-over-ons">
              <About />
            </Route>
            <Route path="/beheer-contact">
              <Contact />
            </Route>
            <Route path="/beheer">
              <Beheer />
            </Route>
            <Route path="/huren/:city" exact>
              <Home />
            </Route>
            <Route path="/huren" exact>
              <Home />
            </Route>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route>
              <PageNotFound onOpen={() => setVerhuren(true)} />
            </Route>
          </Switch>
          <Footer />
        </Router>
        <CookieConsent
          location="top"
          cookieName="cookie-consent"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          buttonText="Ik begrijp het"
        >
          Deze website gebruikt cookies om de gebruikerservaring te verbeteren
        </CookieConsent>
      </LanguageContext.Provider>
    </div>
  );
}

export default App;
