import React from "react";

export default () => {
  const asset1 = require("../../assets/images/ellipse-icon/ellipse_skyblue-1-big.png");
  const asset2 = require("../../assets/images/ellipse-icon/asset-3_2.png");

  return (
    <section
      id="cta"
      class="verhuren-contact__cta spacing ellipse ellipse__right"
    >
      <div class="ellipse_icons ellipse_icons-1">
        <img
          src={asset1}
          alt="asset one"
          style={{ left: "100px", width: "150px" }}
        />
        <img
          src={asset2}
          alt="asset two"
          style={{ left: "35%", width: "50px" }}
        />
      </div>

      <div class="ellipse_shape ellipse_shape--skyblue"></div>
      <div class="container">
        <div class="row">
          <div class="col-md-4 offset-md-8">
            <div class="cta-content text-md-right">
              <h2 class="text-white">
                Volledige <br />
                ontzorging? <br />
                Wij helpen u graag!
              </h2>
              <h4>
                <a class="text-white border-bottom border-light" href="beheer">
                  Wij regelen het beheer
                </a>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
