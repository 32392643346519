import React from "react";

export default () => {
  return (
    <section id="partner-logo" className="spacing pb-0">
      <div className="container">
        <div id="partner-logo-slider" className="owl-carousel">
          <div className="partner-logo-slide">
            <img src="assets/images/partner-logo/1.png" alt="partner-logo" />
          </div>
          <div className="partner-logo-slide">
            <img src="assets/images/partner-logo/2.png" alt="partner-logo" />
          </div>
          <div className="partner-logo-slide">
            <img src="assets/images/partner-logo/3.png" alt="partner-logo" />
          </div>
          <div className="partner-logo-slide">
            <img src="assets/images/partner-logo/4.png" alt="partner-logo" />
          </div>
          <div className="partner-logo-slide">
            <img src="assets/images/partner-logo/5.png" alt="partner-logo" />
          </div>
          <div className="partner-logo-slide">
            <img src="assets/images/partner-logo/6.png" alt="partner-logo" />
          </div>
          <div className="partner-logo-slide">
            <img src="assets/images/partner-logo/1.png" alt="partner-logo" />
          </div>
          <div className="partner-logo-slide">
            <img src="assets/images/partner-logo/2.png" alt="partner-logo" />
          </div>
          <div className="partner-logo-slide">
            <img src="assets/images/partner-logo/3.png" alt="partner-logo" />
          </div>
        </div>
      </div>
    </section>
  );
};
