import React from "react";

const BeheerFeature = () => {
  return (
    <section
      class="spacing spacing--big keppel_color "
      style={{ padding: "24px" }}
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div
              class="text-lg-left"
              style={{ textAlign: "center", margin: "0 0 32px" }}
            >
              <div class="icon-box icon-box--light">
                <i class="fas fa-ruler"></i>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="icon-card icon-card__left">
              <div class="icon-card__icon text-white">
                <i class="fas fa-check-circle"></i>
              </div>

              <div class="icon-card__content text-white">
                <h3>Passende uitstraling</h3>
                <p>
                  Wij denken graag met jou mee over hoe jouw woning het beste
                  tot zijn recht komt en hoe wij een passende uitstraling kunnen
                  creëren. Wij kunnen je adviseren over de styling en de beste
                  fotografie. Zo komt jouw woning verzorgd en aantrekkelijk op
                  de markt.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BeheerFeature;
