import React from "react";

const TOS = () => {
  return (
    <div>
      <section className="spacing" />
      <div className="container">
        <h1>Algemene voorwaarden</h1>
        <h5>Artikel 1: Toepasselijkheid, definities</h5>
        <p>
          Deze voorwaarden zijn van toepassing op alle aanbiedingen en op alle
          (bemiddelings)overeenkomsten die LTWonen hierna te noemen ‘Makelaar’,
          sluit met haar Opdrachtgevers in hun hoedanigheid van
          (aspirant)verhuurder, hierna te noemen ‘Opdrachtgever’.
          <br />
          <br /> Onder bemiddeling wordt verstaan: de inspanningsverplichting
          van Makelaar gericht op het tegen betaling van een
          bemiddelingsvergoeding (courtage) door Opdrachtgever in contact
          brengen van Opdrachtgever met potentiële huurder(s), opdat
          Opdrachtgever met een huurder een huurovereenkomst voor woonruimte
          sluit, een en ander als bedoeld in artikel 7:425 BW.
          <br />
          <br />
          Onder bemiddelingsvergoeding of courtage wordt verstaan de door
          Opdrachtgever aan Makelaar verschuldigde tegenprestatie voor diens
          bemiddelingswerkzaamheden.
          <br />
          <br /> Indien in het navolgende een bepaling specifiek ziet op de
          situatie waarin Opdrachtgever een natuurlijke persoon is die niet
          handelt in de uitoefening van een beroep of bedrijf zal deze worden
          aangeduid als ‘de consument’.
          <br />
          <br /> Bepalingen die afwijken van deze algemene
          bemiddelingsvoorwaarden maken alleen deel uit van de tussenpartijen
          gesloten overeenkomst indien en voor zover partijen dat uitdrukkelijk
          schriftelijk zijn overeengekomen.
          <br />
          <br /> Onder ‘schriftelijk’ wordt in deze algemene
          bemiddelingsvoorwaarden tevens verstaan: per e-mail, per fax of enige
          andere wijze van communicatie die met het oog op de stand der techniek
          en de in het maatschappelijk verkeer geldende opvattingen hiermee
          gelijk kan worden gesteld.
          <br />
          <br /> De door Makelaar te vervaardigen c.q. door Opdrachtgever
          verstrekte schriftelijke adviezen, documenten, (taxatie) rapporten,
          onderzoeken, e.d. zullen in het navolgende worden aangeduid als ‘de
          bescheiden’. Onder ‘de bescheiden’ worden verstaan schriftelijke
          stukken en op andere media vastgelegde werken, zoals op computer
          schijven, op usb-sticks of welke andere gegevensdragers ook. Een en
          ander, tenzij partijen uitdrukkelijk schriftelijk anders zijn
          overeengekomen.
          <br />
          <br /> Indien Opdrachtgever bestaat uit twee of meer (rechts)personen
          zijn zij ieder jegens Makelaar hoofdelijk aansprakelijk voor de
          nakoming van alle verplichtingen jegens Makelaar.
          <br />
          <br /> Wanneer een bepaling uit deze algemene voorwaarden en/of de
          overeenkomst tussen partijen onverbindend, nietig of vernietigbaar
          blijkt te zijn, zal die ongeldigheid of onafdwingbaarheid geen invloed
          hebben op de rechtsgeldigheid en afdwingbaarheid van de overige
          bepalingen van deze voorwaarden en overeenkomst. In het geval een
          bepaling niet rechtsgeldig is of niet afdwingbaar is, zullen partijen
          trachten zo spoedig mogelijk in redelijkheid en billijkheid met elkaar
          overeen te komen omtrent een vervangende bepaling die wel geldig en
          afdwingbaar is en die voor zoveel mogelijk dezelfde commerciële
          strekking en inhoud zal hebben als de bepaling die zij vervangt. De
          overige bepalingen blijven onverkort van kracht. <br />
          <br />
          Indien Makelaar niet binnen bekwame spoed nakoming door Opdrachtgever
          verlangt, laat dit het recht op nakoming van Makelaar onverlet.
          <br />
          <br /> Opdrachtgever kan zich niet beroepen op het feit dat de
          algemene bemiddelingsvoorwaarden niet aan hem ter hand gesteld zijn
          indien Makelaar deze algemene bemiddelingsvoorwaarden al eerder bij
          een andere transactie aan Opdrachtgever ter hand gesteld heeft.
          <br />
          <br /> Makelaar behoudt zich het recht voor om bij gewijzigde
          regelgeving de algemene bemiddelingsvoorwaarden van Makelaar eenzijdig
          te wijzigen.
        </p>
        <br />
        <br />
        <h5>Artikel 2: Overeenkomsten, opdrachten</h5>
        <p>
          Mondelinge afspraken binden Makelaar eerst nadat deze schriftelijk
          door Makelaar zijn bevestigd dan wel zodra Makelaar met instemming van
          Opdrachtgever een aanvang met de uitvoeringshandelingen heeft gemaakt.
          <br />
          <br />
          Aanvullingen of wijzigingen op de algemene bemiddelingsvoorwaarden of
          anderszins wijzigingen of aanvullingen op de overeenkomst worden eerst
          na schriftelijke bevestiging door Makelaar bindend.
        </p>
        <br />
        <br />
        <h5>
          Artikel 3: Verplichtingen van Opdrachtgever, verschuldigdheid van
          courtage
        </h5>
        <p>
          Opdrachtgever dient er voor te zorgen dat de eventueel voor de
          uitvoering van de overeenkomst benodigde gegevens tijdig en in de door
          Makelaar gewenste vorm aan Makelaar ter beschikking worden gesteld.
          <br />
          <br />
          Opdrachtgever zal in alle opzichten zijn medewerking verlenen aan een
          deugdelijke uitvoering van de bemiddelingsovereenkomst door beide
          partijen. Opdrachtgever zal niets doen en/of nalaten dat een
          deugdelijke uitvoering van deze overeenkomst belemmert of kan
          belemmeren.
          <br />
          <br />
          Indien Opdrachtgever de betreffende woonruimte (mede) blijkt te
          verhuren of (mede) in gebruik blijkt te hebben gegeven aan een of meer
          personen of partijen, van wie Opdrachtgever de gegevens van Makelaar
          heeft verkregen, is Opdrachtgever de courtage aan Makelaar
          verschuldigd, ongeacht of de huurovereenkomst door bemiddeling van
          Makelaar tot stand is gekomen.
          <br />
          <br />
          Indien door welke oorzaak dan ook de huurder, met wie Opdrachtgever
          door bemiddeling van Makelaar een huurovereenkomst heeft gesloten,
          niet gaat wonen in de betreffende woonruimte, of indien de
          huurovereenkomst voor deze woonruimte wordt beëindigd, vernietigd of
          ontbonden, blijft Opdrachtgever gehouden de courtage te betalen en
          heeft Opdrachtgever geen recht op gehele of gedeeltelijke restitutie
          daarvan.
          <br />
          <br />
          Indien voor de betreffende woonruimte een vergunning vereist is, dan
          komt het verkrijgen van deze vergunning ten behoeve van Opdrachtgever
          en/of huurder voor rekening en risico van Opdrachtgever en is
          Opdrachtgever gehouden de courtage te voldoen ongeacht of de
          vergunning is of zal worden verleend, tenzij partijen anders zijn
          overeengekomen.
          <br />
          <br />
          Indien Opdrachtgever na het geven van een akkoord voor het verhuren
          van de betreffende woonruimte aan een door Makelaar voorgedragen
          huurder, om redenen welke niet aan Makelaar zijn toe te rekenen, de
          woonruimte niet langer wenst te verhuren aan de betreffende huurder,
          is Opdrachtgever gehouden aan Makelaar een bedrag te voldoen gelijk
          aan de courtage die Opdrachtgever aan Makelaar verschuldigd zou zijn
          geweest indien definitief een huurovereenkomst tot stand zou zijn
          gekomen met de betreffende huurder. Daarnaast is Opdrachtgever
          gehouden Makelaar te vrijwaren voor eventueel door de betreffende
          huurder geleden schade.
          <br />
          <br />
          Indien niet tijdig aan de in dit artikel genoemde verplichtingen is
          voldaan, is Makelaar gerechtigd de uitvoering van de overeenkomst op
          te schorten tot het moment dat Opdrachtgever wel aan deze
          verplichtingen heeft voldaan. De kosten in verband met de opgelopen
          vertraging c.q. de kosten voor het verrichten van extra werkzaam heden
          dan wel de overige hieruit voortvloeiende gevolgen zijn voor rekening
          en risico van Opdrachtgever.
          <br />
          <br />
        </p>
        <h5>Artikel 4: Persoonsgegevens</h5>
        <p>
          Opdrachtgever geeft door de aanvaarding van deze algemene voorwaarden
          zijn ondubbelzinnige toestemming voor de verwerking van gewone
          persoonsgegevens en, als het om de verwerking van bijzondere
          persoonsgegevens gaat, zijn uitdrukkelijke toestemming voor die
          verwerking, voor de overeengekomen doeleinden en de uitvoering van de
          overeenkomst.
          <br />
          <br />
          Opdrachtgever stemt er tevens mee in dat Opdrachtnemer de
          (persoons)gegevens eveneens kan gebruiken met het oog op werving voor
          commerciële en charitatieve doeleinden van haarzelf of derden.
          Daarvoor kunnen de (persoons)gegevens ook aan derden ter beschikking
          worden gesteld. Zie hiervoor de privacyverklaring.
          <br />
          <br />
          Opdrachtnemer spant zich naar redelijkheid in om haar systemen te
          beveiligen tegen verlies en/of tegen enige vorm van onrechtmatig
          gebruik. Opdrachtnemer treft hiervoor passende technische en
          organisatorische maatregelen, waarbij onder meer rekening wordt
          gehouden met de stand van de techniek en de aard van de
          Persoonsgegevens.
          <br />
          <br />
          Om de diensten en de Website optimaal af te kunnen stemmen op de
          wensen van Bezoekers verzamelt Opdrachtnemer geanonimiseerde
          statistische informatie over het surfgedrag van Bezoekers. Het is niet
          mogelijk om aan de hand van deze informatie een Bezoeker te
          identificeren.
          <br />
          <br />
          Om het gebruik van de Website te vergemakkelijken maakt Opdrachtnemer
          gebruik van cookies. Dit zijn kleine bestanden die naar de browser van
          de Bezoeker worden gezonden en worden opgeslagen op de harde schijf.
          Met behulp van cookies wordt geregistreerd hoe en wanneer een Bezoeker
          een website gebruikt. Cookies worden ook gebruikt om wachtwoorden,
          gebruikersnamen en voorkeuren te onthouden. De Bezoeker wordt erop
          gewezen de mogelijkheid te hebben zijn browser zodanig in te stellen
          dat cookies worden geweigerd.
          <br />
          <br />
          Indien Opdrachtgever bezwaar heeft tegen opname van diens
          persoonsgegevens in enige mailinglist e.d. van Opdrachtnemer, zal
          Opdrachtnemer de betreffende gegevens op eerste schriftelijk verzoek
          van Opdrachtgever uit het betreffende bestand verwijderen.
          <br />
          <br />
        </p>
        <h5>Artikel 5: Termijnen</h5>
        <p>
          Opgegeven termijnen waarbinnen door Makelaar de werkzaamheden moeten
          zijn verricht c.q. de bescheiden en/of diensten moeten zijn geleverd
          kunnen nimmer worden beschouwd als fatale termijnen, tenzij partijen
          uitdrukkelijk schriftelijk anders zijn overeengekomen. Indien Makelaar
          haar verplichtingen uit de overeenkomst niet c.q. niet tijdig nakomt,
          dient zij derhalve schriftelijk in gebreke te worden gesteld.
          <br />
          <br />
          Makelaar is bevoegd om – ter zake de nakoming van financiële
          verplichtingen van Opdrachtgever – vooruitbetaling of zekerheid van
          Opdrachtgever te verlangen, alvorens met de te verrichten
          werkzaamheden te starten althans deze voort te zetten.
          <br />
          <br />
        </p>
        <h5>Artikel 6: Voortgang, uitvoering overeenkomst</h5>
        <p>
          Makelaar is gehouden de overeenkomst op deskundige, zorgvuldige wijze
          en conform de hiervoor in haar branche geldende maatstaven uit te
          voeren.
          <br />
          <br />
          Makelaar kan niet eerder verplicht worden met de uitvoering van de
          werkzaamheden te beginnen, dan nadat alle daarvoor noodzakelijk
          gegevens in haar bezit zijn en zij de eventueel overeengekomen
          (vooruit)betaling heeft ontvangen.
          <br />
          <br />
          Opdrachtgever garandeert dat alle gegevens, zoals -maar niet
          uitsluitend- naam, adres, woonplaats, e-mailadres, bankgegevens,
          omschrijving van de woonruimte, die voor de uitvoering van de
          overeenkomst nodig zijn volledig correct zijn.
          <br />
          <br />
          Opdrachtgever dient er voor zorg te dragen dat de informatie in de
          door de Makelaar te plaatsen, cq. geplaatste, advertentie overeenkomst
          met de werkelijkheid en derhalve juist is. Opdrachtgever bepaalt,
          desgewenst in overleg met de Makelaar, de hoogte van de huurprijs.
          Opdrachtgever is en blijft zelf verantwoordelijk voor de juiste
          vaststelling van de huurprijs, nimmer zal de Makelaar aansprakelijk
          zijn voor een onjuiste vaststelling van de huurprijs ingevolge de
          huurwetgeving / puntensysteem.
          <br />
          <br />
          Opdrachtgever is zelf verantwoordelijk en aansprakelijk voor de inhoud
          van de namens hem of haar geplaatste informatie in (online)
          advertenties cq. mededelingen van Makelaar aan (aspirant) huurders.
          Opdrachtgever garandeert dat de inhoud van de namens hem of haar
          geplaatste informatie niet strijdig is met de in Nederland geldende
          wet- en regelgeving, dan wel onrechtmatig is.
          <br />
          <br />
        </p>
        <h5>Artikel 7: Duur overeenkomst, inspanningsverplichting makelaar</h5>
        <p>
          Een overeenkomst tot bemiddeling loopt voor onbepaalde tijd, tenzij
          schriftelijk anders is overeengekomen.
          <br />
          <br />
          Makelaar zal zich naar beste kunnen en weten inspannen om het door
          Opdrachtgever gewenste c.q. beoogde resultaat te bereiken. Dit is te
          allen tijde een inspanningsverplichting van Makelaar en geen
          resultaatsverplichting. Indien voornoemd resultaat uitblijft, ontslaat
          dit Opdrachtgever derhalve niet van zijn verplichtingen jegens
          Makelaar, met uitzondering van eventuele verplichtingen die door
          partijen uitdrukkelijk gekoppeld zijn aan het bereiken van het beoogde
          resultaat.
        </p>
        <h5>Artikel 8: Einde en opzegging van de bemiddelingsovereenkomst</h5>
        <p>
          Tenzij anders overeengekomen en onverminderd het overigens bepaalde in
          deze algemene bemiddelingsvoorwaarden, eindigt de
          bemiddelingsovereenkomst onder meer door:
          <ul>
            <li>vervulling van de overeenkomst door Makelaar;</li>
            <li>opzegging door Opdrachtgever;</li>
            <li>opzegging door Makelaar.</li>
            <li>
              De overeenkomst is vervuld zodra het beoogde resultaat is bereikt;
            </li>
          </ul>
          Opdrachtgever en Makelaar zijn bevoegd om deze overeenkomst op ieder
          moment op te zeggen.
          <br />
          <br />
          Partijen kunnen aan de beëindiging van de overeenkomst door opzegging
          geen recht op schadevergoeding ontlenen, tenzij wordt opgezegd vanwege
          het tekortschieten in de nakoming van een of meer verplichtingen door
          de andere partij.
          <br />
          <br />
        </p>
        <h5>Artikel 9: Reclames en klachten</h5>
        <p>
          Opdrachtgever is verplicht direct bij het in ontvangst nemen van
          bescheiden, zoals de (concept-) huurovereenkomst van Makelaar
          ommegaand tot controle van deze bescheiden over te gaan. Eventuele
          onvolkomenheden dienen uiterlijk binnen 2 werkdagen na ontvangst van
          de bescheiden schriftelijk aan Makelaar te worden gemeld. Een latere
          melding van onjuistheden kan nimmer aan de Makelaar kunnen worden
          tegengeworpen.
          <br />
          <br />
          Overige reclames – waaronder klachten met betrekking tot de verrichte
          werkzaamheden c.q. de verleende diensten – dienen per aangetekend
          schrijven uiterlijk binnen 2 maanden na ontdekking dan wel nadat
          Opdrachtgever deze redelijkerwijs had moeten ontdekken door
          Opdrachtgever aan Makelaar te worden gemeld, bij gebreke waarvan
          Opdrachtgever geen beroep meer kan doen op eventuele gebreken in de
          prestatie van de Makelaar.
          <br />
          <br />
        </p>
        <h5>Artikel 10: Aansprakelijkheid en vrijwaring</h5>
        <p>
          Indien Makelaar bemiddelt bij het tot stand brengen van een
          huurovereenkomst tussen verhuurder en huurder is Makelaar nimmer
          partij bij de huurovereenkomst en is zij niet aansprakelijk voor de
          inhoud en de uitvoering van de huurovereenkomst door partijen daarbij.
          Makelaar is in geen geval aansprakelijk voor de schade van
          Opdrachtgever die het gevolg is van de situatie dat de huurprijs en/of
          de overeengekomen service(kosten) en/of de bijkomende al dan niet
          eenmalige vergoedingen niet in overeenstemming is/zijn met de wet.
          <br />
          <br />
          Makelaar kwijt zich van haar taak zoals van een bedrijf in haar
          branche mag worden verwacht, maar aanvaardt geen enkele
          aansprakelijkheid voor schade, met inbegrip van gevolgschade,
          bedrijfsschade, winstdervingen en/of stagnatieschade, die het gevolg
          is van handelen of nalaten van Makelaar, haar personeel dan wel door
          haar ingeschakelde derden.
          <br />
          <br />
          Makelaar is niet aansprakelijk voor schade die Opdrachtgever lijdt ten
          gevolge van handelingen of het nalaten hiervan door de andere partij
          bij de door bemiddeling van Makelaar tot stand gekomen
          huurovereenkomst.
          <br />
          <br />
          De in dit artikel opgenomen beperkingen van de aansprakelijkheid
          gelden niet indien de schade te wijten is aan opzet en/of bewuste
          roekeloosheid van Makelaar. Opdrachtgever dient dit aan te tonen.
          <br />
          <br />
          Onverminderd het bepaalde in de overige leden van dit artikel is de
          aansprakelijkheid te allen tijde beperkt tot het bedrag van de door de
          assuradeur van Makelaar in het voorkomende geval te verstrekken
          uitkering, voor zover Makelaar hiervoor verzekerd is. De Makelaar is
          hiertoe niet verplicht.
          <br />
          <br />
          Indien Makelaar niet verzekerd is als bedoeld in het vorige lid is de
          aansprakelijkheid van Makelaar te allen tijde beperkt tot maximaal
          twee maal de hoogte van de door Makelaar aan Opdrachtgever voor haar
          werkzaamheden en/of diensten in rekening gebrachte en/of te brengen
          courtage.
          <br />
          <br />
          Makelaar is niet aansprakelijk voor de gevolgen van eventuele schade
          en/of gebreken aan de woning die bij de aanvaarding door huurder van
          de woning en/of het vertrek van huurder uit de woning aanwezig is. Het
          is aan opdrachtgever zelf de woning te controleren op eventuele schade
          en/of gebreken en zo nodig de huurder daarop aan te spreken.
          <br />
          <br />
          Opdrachtgever garandeert dat de door hem verstrekte informatie, geen
          inbreuk maakt op rechten van derden of anderszins onrechtmatig is.
          Gebruiker vrijwaart de Makelaar volledig voor alle mogelijke
          aanspraken van derden -in de ruimste zin des woords-.
          <br />
          <br />
        </p>
        <h5>Artikel 11: Betaling</h5>
        <p>
          Tenzij anders overeengekomen dient Opdrachtgever al hetgeen hij aan
          Makelaar verschuldigd is in één keer te betalen binnen 14 dagen na
          factuurdatum. Deze termijn geldt als fatale termijn. Bij niet tijdige
          betaling:
          <br />
          <br />
          zal Opdrachtgever aan Makelaar een vertragingsrente verschuldigd zijn
          ter grootte van 1% per maand cumulatief te berekenen over de hoofdsom.
          Gedeelten van een maand worden als volle maand beschouwd;
          <br />
          <br />
          zal Opdrachtgever, na daartoe door Makelaar te zijn gemaand, ter zake
          van buitengerechtelijke kosten minimaal verschuldigd zijn 15% van de
          som van de hoofdsom en de vertragingsrente met een minimum van €
          40,00;
          <br />
          <br />
          Al hetgeen Opdrachtgever aan Makelaar verschuldigd is wordt door
          Opdrachtgever tijdig voldaan zonder enig beroep op korting,
          opschorting, verrekening of ongedaan making.
          <br />
          <br />
          Ter keuze van Makelaar kan in voorgaande of daarmee overeenstemmende
          omstandigheden, zonder nadere ingebrekestelling of rechterlijke
          tussenkomst, de overeenkomst geheel of gedeeltelijk worden ontbonden,
          al dan niet gecombineerd met een eis tot schadevergoeding.
          <br />
          <br />
          Indien Opdrachtgever niet tijdig aan diens betalingsverplichtingen
          heeft voldaan, is Makelaar bevoegd de nakoming van de jegens
          Opdrachtgever aangegane verplichtingen tot levering c.q. tot de
          uitvoering van werkzaamheden op te schorten totdat de betaling is
          geschied of deugdelijke zekerheid hiervoor is gesteld. Hetzelfde geldt
          reeds vóór het moment van in verzuim zijn indien Makelaar het
          redelijke vermoeden heeft dat er redenen zijn om aan de
          kredietwaardigheid van Opdrachtgever te twijfelen.
          <br />
          <br />
          Door Opdrachtgever gedane betalingen strekken steeds ter afdoening van
          alle verschuldigde rente en kosten en strekken vervolgens ter
          afdoening van opeisbare facturen die het langst openstaan, tenzij
          Opdrachtgever bij betaling uitdrukkelijk schriftelijk vermeldt dat de
          voldoening betrekking heeft op een latere factuur.
          <br />
          <br />
        </p>
        <h5>Artikel 12: Faillissement, beschikkingsonbevoegdheid e.d.</h5>
        <p>
          Onverminderd het bepaalde in de overige artikelen van deze voorwaarden
          wordt de tussen Opdrachtgever en Makelaar gesloten overeenkomst
          ontbonden zonder dat rechterlijke tussenkomst en zonder dat enige
          ingebrekestelling zal zijn vereist, op het tijdstip waarop
          Opdrachtgever:
          <br />
          <br />
          <ul>
            <li>in staat van faillissement wordt verklaard;</li>
            <li>(voorlopige) surséance van betaling aanvraagt;</li>
            <li>door executoriale beslaglegging wordt getroffen;</li>
            <li>onder curatele of onder bewind wordt gesteld;</li>
          </ul>
          anderszins de beschikkingsbevoegdheid of handelingsbekwaamheid met
          betrekking tot diens vermogen of delen ervan verliest.
          <br />
          <br />
          Het in lid 1 van dit artikel bepaalde is van toepassing, tenzij de
          curator of de bewindvoerder de uit de overeenkomst voortvloeiende
          verplichtingen als boedelschuld erkent.
          <br />
          <br />
        </p>
        <h5>Artikel 13: Geheimhouding</h5>
        <p>
          Makelaar zal alle van Opdrachtgever in het kader van deze opdracht aan
          Makelaar ter beschikking gestelde informatie vertrouwelijk behandelen,
          voor zover dat redelijkerwijs van Makelaar kan worden gevergd.
          <br />
          <br />
          Het is Opdrachtgever verboden informatie, die hij ter zake van deze
          opdracht van Makelaar ontvangt, aan derden door te geven, zulks op
          straffe van vergoeding van alle schade, die hierdoor voor Makelaar zal
          ontstaan.
          <br />
          <br />
        </p>
        <h5>Artikel 14: Bevoegde rechter, toepasselijk recht</h5>
        <p>
          Op de tussen Makelaar en Opdrachtgever gesloten overeenkomst is
          uitsluitend Nederlands recht van toepassing. De geschillen die uit
          deze overeenkomst voortvloeien zullen eveneens naar Nederlands recht
          worden beslecht.
          <br />
          <br />
          Eventuele geschillen zullen worden beslecht door de bevoegde
          Nederlandse rechter, zij het dat Makelaar, voor zover de wet zich daar
          niet dwingendrechtelijk tegen verzet, de bevoegdheid toekomt een zaak
          aanhangig te maken voor de bevoegde rechter in de plaats waar Makelaar
          is gevestigd.
          <br />
          <br />
        </p>
      </div>
      <section className="spacing" />
    </div>
  );
};

export default TOS;
