import React from "react";

export default ({ green, blue, backgroundColor }) => {
  const getClass = () => {
    return green
      ? "spacing aq-bg--shape"
      : blue
      ? "spacing aq-bg--shape skyblue-bg"
      : "spacing";
  };

  return (
    <section
      id="ask-question"
      className={getClass()}
      style={{ backgroundColor }}
    >
      <div className="container">
        <div
          className="section-header text-center"
          style={(green || blue) && { color: "white" }}
        >
          <h3>Heb je een vraag?</h3>
          <p>Neem gerust contact met ons op</p>
        </div>

        <div className="row">
          <div className="col-lg-4">
            <div className="icon-card box-shadow icon-card--2">
              <h3>Bel ons</h3>

              <div className="icon-card__icon icon-card__icon--2">
                <i className="fas fa-phone-volume"></i>
              </div>

              <div className="icon-card__content">
                <h4>
                  <a href="tel:+31505690406" style={{ color: "black" }}>
                    050-5690406
                  </a>
                </h4>
                <p>Iedere werkdag van 9.00 tot 17.00 uur</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="icon-card box-shadow icon-card--2">
              <h3>Chat met ons</h3>

              <div className="icon-card__icon icon-card__icon--2">
                <i className="far fa-comments" aria-hidden="true"></i>
              </div>

              <div className="icon-card__content">
                <h4>Gebruik de button</h4>
                <p>Rechts in het scherm</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="icon-card box-shadow icon-card--2">
              <h3>Of stuur een e-mail</h3>

              <div className="icon-card__icon icon-card__icon--2">
                <i className="far fa-envelope"></i>
              </div>

              <div className="icon-card__content">
                <h4>
                  <a href="mailto:info@ltwonen.nl" style={{ color: "black" }}>
                    info@LTWonen.nl
                  </a>
                </h4>
                <p>Wij reageren zo snel mogelijk</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
