import React, { useEffect } from "react";
import Questions from "../components/home/Questions";
import Hero from "../components/verhuren/Hero";
import Huren from "../components/verhuren/Huren";
import Packages from "../components/verhuren/Packages";
import Partners from "../components/verhuren/Partners";
import Quote from "../components/verhuren/Quote";
import Steps from "../components/verhuren/Steps";

export default () => {
  const image = require("../assets/images/verhuren_hero--bg.jpg");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Hero
        title="Je woning verhuren?"
        subtitle="Wij helpen u adverteren op o.a. Pararius & Funda en bereiken miljoenen potentiële huurders!"
        link="verhuren-pakketten"
        linktext="Bekijk de pakketten"
        image={image}
      />
      <Packages />
      <Steps />
      <Huren title />
      <Partners />
      <Quote />
      <Questions blue />
    </div>
  );
};
