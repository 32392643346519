import React, { useEffect } from "react";
import Hero from "../components/home/Hero";
import Questions from "../components/home/Questions";
import Subscribe from "../components/home/Subscribe";

export default () => {
  const image = require("../assets/images/contact-bg.jpg");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Hero title="Waar kunnen we je mee helpen?" image={image} />
      <Questions />
      <Subscribe />
    </div>
  );
};
