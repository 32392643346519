import { createStore, combineReducers } from "redux";
import housesReducer from "../features/houses/housesReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const reducers = combineReducers({
  houses: housesReducer,
});

const persistConfig = {
  key: "houseType",
  storage: storage,
  whitelist: ["houses"], // which reducer want to store
};

const pReducer = persistReducer(persistConfig, reducers);

const store = createStore(pReducer);

const persistor = persistStore(store);

export { persistor, store };
