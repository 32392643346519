import React from "react";
import { useHistory } from "react-router-dom";

export default ({ details }) => {
  const history = useHistory();

  return details ? (
    <section id="search-area" className="search-form-2">
      <div className="container">
        <div className="search-filter-wrap">
          <form action="">
            <div className="form-row">
              <div className="col-md-6">
                <div className="signle-search-card">
                  <h3>{`${details.street} ${details.house_number}${
                    details.apartment &&
                    (isNaN(Number(details.apartment))
                      ? details.apartment
                      : " - " + details.apartment)
                  }`}</h3>
                  <p>{`${details?.zipcode} ${details?.city.toUpperCase()}`}</p>
                </div>
              </div>

              <div className="col-md-3">
                <div className="signle-search-card text-md-center">
                  <h3>{`€${details.rent} /mnd`}</h3>
                  <p>{`Eenmalig €${details.one_time_cost} kosten huurder`}</p>
                </div>
              </div>

              <div className="col-md-3">
                <div className="ctw-button-wrap signle-search-card">
                  <button
                    type="submit"
                    onClick={() => history.push("/contact")}
                  >
                    Direct Huren
                  </button>
                  <div className="button-link">
                    {/* <Link style={{ textDecoration: "none" }} to="">
                      <i className="fa fa-share"></i>
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  ) : (
    <div />
  );
};
