import React from "react";

const BeheerFeature = () => {
  return (
    <section class="spacing spacing--big" style={{ padding: "30px 0 24px" }}>
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="icon-card icon-card__left">
              <div class="icon-card__icon">
                <i class="fas fa-check-circle"></i>
              </div>

              <div class="icon-card__content text-dark">
                <h3>Geschikte huurders</h3>
                <p>
                  Om de geschikte huurders voor jouw woning te vinden, zoeken
                  wij de perfecte kandidaten voor je uit. Zij zullen zorgvuldig
                  gescreend worden. Zo loop jij zo min mogelijk risico bij het
                  verhuren van jouw kamer, studio of woning. Wel zo prettig!
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div
              class="text-lg-right"
              style={{ textAlign: "center", margin: "32px 0 0" }}
            >
              <div class="icon-box">
                <i class="fas fa-chart-line"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BeheerFeature;
