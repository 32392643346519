import React, { useCallback, useContext, useEffect, useState } from "react";
import Package from "../components/verhuren/Package";
import Form from "../components/form/Form";
import { useParams } from "react-router-dom";
import { LanguageContext } from "../context/LanguageContext";
import { getPackages } from "../api/packages/Packages";

const image = require("../assets/images/contact-bg-.jpg");

const OrderPackage = () => {
  const [packages, setPackages] = useState();

  const { id, extra, technical, financial, energy } = useParams();
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const loadPackages = useCallback(async () => {
    const response = await getPackages(language);
    setPackages(response);
  }, [language]);

  useEffect(() => {
    loadPackages();
  }, [loadPackages]);

  const getExtras = () => {
    let extras = "";
    if (extra > 0) {
      if (technical > 0) {
        extras = "Technisch beheer, ";
      }
      if (financial > 0) {
        extras = "Financieel beheer, ";
      }
      if (energy > 0) {
        extras = "Jaarlijkse energiecontract check, ";
      }
      extras = extras.substring(0, extras.length - 2);
    } else {
      extras = "Geen";
    }

    return extras;
  };

  return (
    <div>
      <div class="image" />
      <section
        id="pricing-table"
        class="spacing"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div class="container">
          <div class="card-deck">
            <Package
              pakket={packages?.find((p) => p.slug === id)}
              withoutButton
              extraEnabled={extra > 0}
              technicalOn={technical > 0}
              financialOn={financial > 0}
              energyOn={energy > 0}
            />
            <Form
              pkg={packages?.find((p) => p.slug === id).name}
              extras={getExtras()}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default OrderPackage;
