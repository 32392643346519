import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default ({
  pakket,
  financialManagement,
  withoutButton,
  extraEnabled,
  technicalOn,
  financialOn,
  energyOn,
}) => {
  const [extrasEnabled, setExtraEnabled] = useState(extraEnabled);

  const technischbeheerCost = 30;
  const financieelBeheerCost = 20;

  const [technical, setTechnical] = useState(technicalOn);
  const [financial, setFinancial] = useState(financialOn);
  const [energy, setEnergy] = useState(energyOn);

  const [monthlyPriceEnabled, setMonthlyPriceEnabled] = useState();

  const [explanationOpen, setExplanationOpen] = useState();

  const getMonthlyPrice = () => {
    let cost = pakket?.monthly_price || 0;
    if (extrasEnabled) {
      if (technical) {
        cost += technischbeheerCost;
      }
      if (financial) {
        cost += financieelBeheerCost;
      }
    }
    return `€${cost},00`;
  };

  useEffect(() => {
    setMonthlyPriceEnabled(
      (extrasEnabled && (technical || financial)) || pakket?.monthly_price > 0
    );
  }, [technical, financial, extrasEnabled]);

  useEffect(() => {
    setExtraEnabled(technical || financial || energy);
  }, [technical, financial, energy]);

  return (
    <div
      className="card pt-card box-shadow border-0"
      style={{ maxWidth: "360px" }}
    >
      <div className="pt__plan equal-flex">
        <h3>{pakket?.name}</h3>
      </div>

      <div className="pt__progress equal-flex">
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${pakket?.progress}%` }}
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>

        <p>
          {pakket?.description_nl ||
            pakket?.description_eng ||
            pakket?.description_de}
        </p>
      </div>

      <div className="card-body pt-list-item p-0 equal-flex">
        <h5>Inclusief bij {pakket?.name}</h5>
        <ul className="list-unstyled">
          {pakket?.package_details?.reverse().map((detail, index) => (
            <li key={index}>
              {detail.description_nl ||
                detail.description_eng ||
                detail.description_de}
            </li>
          ))}
        </ul>
      </div>

      <div class="pt-switch-btn__wrap">
        <div class="pt-switch-btn">
          <div class="pt-switch-header">
            <h6>
              Upgraden met <strong>Extra</strong>
            </h6>
            <a
              style={{
                color: "#007bff",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => setExplanationOpen(true)}
            >
              Wat is Extra?
            </a>
            {explanationOpen && (
              <p>
                <b>Extra text</b>
              </p>
            )}
          </div>
        </div>

        <div class="single-switch">
          <span>
            <h6>Technisch beheer</h6>
            <p>€30 p/m</p>
          </span>

          <div
            class="custom-control custom-switch"
            onClick={() => setTechnical(!technical)}
          >
            <input
              type="checkbox"
              class="custom-control-input"
              checked={technical}
            />
            <label class="custom-control-label" for="pt-switch_2">
              switch
            </label>
          </div>
        </div>

        {financialManagement && (
          <div class="single-switch">
            <span>
              <h6>Financieel beheer</h6>
              <p>€20 p/m</p>
            </span>

            <div
              class="custom-control custom-switch"
              onClick={() => setFinancial(!financial)}
            >
              <input
                type="checkbox"
                class="custom-control-input"
                checked={financial}
              />
              <label class="custom-control-label" for="pt-switch_3">
                switch
              </label>
            </div>
          </div>
        )}

        <div class="single-switch">
          <span>
            <h6>Jaarlijkse energiecontract check</h6>
            <p>
              Check of het contract goedkoper kan.
              <br />
              Gratis
            </p>
          </span>

          <div
            class="custom-control custom-switch"
            onClick={() => setEnergy(!energy)}
          >
            <input
              type="checkbox"
              class="custom-control-input"
              checked={energy}
            />
            <label class="custom-control-label" for="pt-switch_4">
              switch
            </label>
          </div>
        </div>

        <div class="pt-switch-footer text-right">
          <span>Extra kosten</span>
          <h4 class="mb-0">{getMonthlyPrice()}</h4>
          <span>Per maand</span>
        </div>
      </div>

      <div
        className="pt-price-box equal-flex"
        style={{ margin: monthlyPriceEnabled && "10px 0" }}
      >
        <div className="pt-price-title">
          <h6 className="mb-0">Basis pakket</h6>
          {extrasEnabled && <strong>+ Extra</strong>}
        </div>

        {pakket?.set_price && !monthlyPriceEnabled && (
          <div className="pt-price-amount equal-flex">
            <span>Totaal bedrag</span>
            <h4>{pakket?.set_price}</h4>
          </div>
        )}

        {pakket?.set_price && monthlyPriceEnabled && (
          <div className="pt-price-amount equal-flex">
            <span style={{ display: "block" }}>Totaal bedrag</span>
            <small>
              <i>Eenmalig</i>
            </small>
            <h4>{pakket?.set_price}</h4>
            <small>
              <i>Maandelijks</i>
            </small>
            <h4>{getMonthlyPrice()}</h4>
          </div>
        )}
      </div>

      {!withoutButton && (
        <div className="pt-price-btn equal-flex">
          <div className="sub-btn">
            <Link
              to={`/pakket/${pakket?.slug}/${extrasEnabled ? 1 : 0}/${
                technical ? 1 : 0
              }/${financial ? 1 : 0}/${energy ? 1 : 0}`}
            >
              Naar aanvragen
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};
