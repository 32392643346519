import React from "react";
import { Link } from "react-router-dom";

export default ({ houses }) => {
  return (
    <section id="aanbod" className="spacing">
      <div className="container">
        <h3>Ons Aanbod</h3>
        <p>Het nieuwste aanbod</p>

        <div className="row">
          {houses?.map((house, index) => (
            <Link
              style={{ textDecoration: "none", color: "black" }}
              className="col-lg-3 col-md-6 mb-5 mb-lg-0"
              key={index}
              to={`/detail/${house.zipcode}/${house.house_number}/${house.apartment}`}
            >
              <div className="product-box box-shadow">
                {house.new && (
                  <span className="badge new-product box-shadow">Nieuw</span>
                )}
                <div className="product-box__bg product-box__bg--1">
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}${house.preview_image}`}
                    alt="product-box__bg"
                  />
                </div>
                <div className="product-box__content">
                  <h4 className="product-box__title">
                    {`${house.street} ${house.house_number}${
                      house.apartment &&
                      (isNaN(Number(house.apartment))
                        ? house.apartment
                        : " - " + house.apartment)
                    }`}
                  </h4>
                  <p>{`${house.zipcode} ${house.city.toUpperCase()}`}</p>
                  <h5 className="product-price">{`€${house.rent} /mnd`}</h5>
                </div>
              </div>
            </Link>
          ))}
        </div>
        <a href="/aanbod" className="readmore-btn mt-4">
          Bekijk alles
        </a>
      </div>
    </section>
  );
};
