import React from "react";
import { Link } from "react-router-dom";

export default () => {
  const logo = require("../assets/images/logo_white.svg");

  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="row">
                <div className="col-lg-3 col-lmd-6">
                  <div className="footer-widget">
                    <h5>Huren</h5>
                    <ul className="footer-menu">
                      <li>
                        <a href="/">Huren</a>
                      </li>
                      <li>
                        <a href="/aanbod">Aanbod</a>
                      </li>
                      <li>
                        <a href="/over-ons">Over ons</a>
                      </li>
                      <li>
                        <a href="/contact">Klantenservice</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-lmd-6">
                  <div className="footer-widget">
                    <h5>Verhuren</h5>
                    <ul className="footer-menu">
                      <li>
                        <a href="/verhuren">Verhuren</a>
                      </li>
                      <li>
                        <a href="/verhuren-pakketten">Pakketten</a>
                      </li>
                      <li>
                        <a href="/verhuren-over-ons">Over Ons</a>
                      </li>
                      <li>
                        <a href="/verhuren-contact">Contact</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-lmd-6">
                  <div className="footer-widget">
                    <h5>Beheer</h5>
                    <ul className="footer-menu">
                      <li>
                        <a href="/beheer">Beheer</a>
                      </li>
                      <li>
                        <a href="/beheer-contact">Contact</a>
                      </li>
                      <li>
                        <a href="/algemene-voorwaarden">Algemene voorwaarden</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-lmd-6">
                  <div className="footer-widget">
                    <h5>Contact</h5>

                    <div className="contact-info mb-4">
                      <a href="tel:+31505690406" className="mb-0">
                        (+31) 050-5690406
                      </a>
                      <br />
                      <a href="mailto:info@ltwonen.nl">info@ltwonen.nl</a>
                    </div>

                    <address>
                      <b>Brugstaat 7a-1</b> <br />
                      9712 AA Groningen
                      <br />
                      Nederland
                    </address>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <Link to="/" className="footer-logo">
                <img src={logo} alt="logo_white" />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container">
          <div className="border-top"></div>
          <div className="row">
            <div className="col-lg-6">
              <div className="copyright">LT Wonen 2021</div>
            </div>
            <div className="col-lg-6">
              <div className="social-items">
                <a href="https://www.instagram.com">
                  <i className="fa fa-instagram"></i>
                </a>
                <a href="https://www.youtube.com">
                  <i className="fa fa-youtube"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
