import React from "react";

const BeheerFeature = () => {
  return (
    <section class="spacing spacing--big" style={{ padding: "24px 0 30px" }}>
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="icon-card icon-card__left">
              <div class="icon-card__icon">
                <i class="fas fa-check-circle"></i>
              </div>

              <div class="icon-card__content text-dark">
                <h3>Social Media</h3>
                <p>
                  Jouw woningaanbod komt ook terecht op onze social media.
                  Hiermee vergroten we het bereik. Zo ben jij absoluut verzekerd
                  dat jouw woning, kamer, studio, appartement of huis zo snel
                  mogelijk weer verhuurd wordt.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div
              class="text-lg-right"
              style={{ textAlign: "center", margin: "32px 0 0" }}
            >
              <div class="icon-box">
                <i class="fab fa-instagram"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BeheerFeature;
