import React from "react";

export default ({
  title,
  text,
  textColor,
  icon,
  color,
  backgroundColor,
  whiteIcons,
}) => {
  return (
    <div className="col-lg-4">
      <div className="icon-card">
        <div className="icon-card__icon box-shadow" style={{ backgroundColor }}>
          {icon ? (
            <img
              alt="USP Icon"
              src={icon}
              style={whiteIcons && { filter: "brightness(10000%)" }}
            />
          ) : (
            <i className="fas fa-phone-volume" style={{ color }}></i>
          )}
        </div>

        <div className="icon-card__content">
          <h4 style={{ color: textColor }}>{title ? title : "Title"}</h4>
          <p style={{ color: textColor }}>{text ? text : "Text"}</p>
        </div>
      </div>
    </div>
  );
};
