import React from "react";

export default ({ title, link, linkText, image }) => {
  return (
    <section
      id="hero"
      className="spacing home_hero ellipse"
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className="ellipse_shape"></div>

      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <div className="home-hero__content">
              <h1 className="text-white">{title}</h1>
              <a href={link}>{linkText}</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
