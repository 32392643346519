import Axios from "axios";

const baseURL = process.env.REACT_APP_BACKEND_URL + "ltwonen/";

// The Axios instance, client side api object.
const api = Axios.create({
  baseURL: baseURL,
  timeout: 15000,
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Token ${process.env.REACT_APP_API_KEY}`,
  },
});

export default api;
