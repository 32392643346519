import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCities } from "../../api/pages/Pages";
import {
  selectFilters,
  setSelectedFilters,
} from "../../features/houses/housesReducer";

export default () => {
  const filters = useSelector(selectFilters);
  const dispatch = useDispatch();
  const [cities, setCities] = useState();

  useEffect(() => {
    retrieveCities();
  }, [filters]);

  const retrieveCities = async () => {
    const result = await getCities();
    setCities(result.map((r) => r.city));
  };

  const [newCity, setNewCity] = useState();
  const [search, setSearch] = useState();

  return (
    <section id="search-area" className="search-form-2">
      <div className="container">
        <div className="search-filter-wrap">
          <form action="">
            <div className="form-row">
              <div className="col-md-6">
                <div className="search-keyword-input">
                  <select
                    name="location"
                    className="select_menu"
                    value={filters?.location}
                    onChange={(e) => {
                      dispatch(
                        setSelectedFilters({
                          ...filters,
                          location: e.target.value,
                        })
                      );
                    }}
                  >
                    {cities?.map((city) => (
                      <option value={city}>{city}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="search-keyword-input"
                  style={{ display: "flex" }}
                >
                  <input
                    type="search"
                    name="house"
                    id="search-keyword"
                    placeholder="Zoekwoord"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <button
                    className="search-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(
                        setSelectedFilters({
                          ...filters,
                          search: search,
                          city: newCity,
                        })
                      );
                    }}
                  >
                    <a href="##" style={{ textDecoration: "none" }}>
                      <i className="fa fa-search"></i>
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};
