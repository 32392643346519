import React from "react";

export default ({ title, subtitle, linktext, link, image }) => {
  return (
    <section
      id="hero"
      className="spacing home_hero home_hero--only verhuren_hero ellipse"
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className="ellipse_shape ellipse_shape--skyblue"></div>

      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="home-hero__content">
              <h1 className="text-white">{title}</h1>
              {subtitle && <h4>{subtitle}</h4>}
              {(link || linktext) && (
                <h4>
                  <a href={link}>{linktext}</a>
                </h4>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
