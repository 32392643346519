import React, { useState } from "react";
import { registerEmail } from "../../api/pages/Pages";

export default () => {
  const asset1 = require("../../assets/images/ellipse-icon/asset-3_1.png");
  const asset2 = require("../../assets/images/ellipse-icon/asset-3_2.png");

  const [email, setEmail] = useState();
  const [sent, setSent] = useState(false);

  const register = async () => {
    const result = await registerEmail(email);
    setSent(result.status);
  };

  return (
    <section id="cta" className="spacing ellipse">
      <div className="ellipse_icons ellipse_icons-3">
        <img src={asset1} alt="" />
        <img src={asset2} alt="" />
      </div>
      <div className="ellipse_shape"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="cta-content">
              <h2 className="text-white mb-5">
                Altijd als eerste op de hoogte van het woningaanbod?
              </h2>

              {!sent && (
                <form className="form_inline">
                  <input
                    className="mb-0 box-shadow"
                    type="email"
                    name="email"
                    placeholder="E-mailadres"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button
                    className="mb-0 box-shadow"
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      register();
                    }}
                  >
                    Go
                  </button>
                </form>
              )}
              {sent && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#FFFFFF",
                  }}
                >
                  <i
                    class="fas fa-check-circle"
                    style={{
                      fontSize: "24px",
                    }}
                  ></i>
                  <h4
                    style={{
                      color: "#FFFFFF",
                      fontSize: "24px",
                      margin: "0 0 0 8px",
                    }}
                  >
                    We houden je op de hoogte!
                  </h4>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
