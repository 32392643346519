import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import { LanguageContext } from "../context/LanguageContext";
import { getCities, getMeta } from "../api/pages/Pages";

let touchesMenu = false;

export default ({ onVerhurenChange, verhuren }) => {
  const logo = require("../assets/images/logo_black.svg");

  const history = useHistory();
  const context = useContext(LanguageContext);
  const [meta, setMeta] = useState();
  const [menuOpen, setMenuOpen] = useState();
  const [cities, setCities] = useState();

  const [selectedLink, setSelectedLink] = useState(
    window.location.pathname.substr(1, window.location.pathname.length - 1)
  );

  const getMetas = async () => {
    const meta = await getMeta(history.location.pathname);
    setMeta(meta);
  };

  const retrieveCities = async () => {
    const result = await getCities();
    setCities(result.map((r) => r.city));
  };

  useEffect(() => {
    getMetas();
    retrieveCities();
  }, [history.location]);

  return (
    <header>
      {meta && (
        <Helmet>
          <title>{meta.meta_title}</title>
          <meta
            name="description"
            content={
              meta?.meta_text ||
              "✓ Grootste aanbod van Groningen ✓ Specialist in verhuur ✓ Appartementen, woningen, studio's ✓ Expats en Locals ✓ Kijk direct en vind je droomhuis"
            }
          />
        </Helmet>
      )}
      <div className="header-top">
        <div className="container">
          <div className="header-top__wrapper">
            <div className="header-top__left">
              <a
                className="logo mobile_off"
                href="/"
                onClick={() => onVerhurenChange(false)}
              >
                <img src={logo} alt="BRAND LOGO" />
              </a>
              <ul className="secondary-menu">
                <li
                  className={
                    !verhuren && !history.location.pathname.includes("beheer")
                      ? "active"
                      : ""
                  }
                >
                  <a href="/huren" onClick={() => onVerhurenChange(false)}>
                    Huren
                  </a>
                </li>
                <li className={verhuren ? "active" : ""}>
                  <a
                    href="/verhuren"
                    onClick={() => {
                      setSelectedLink("verhuren");
                    }}
                  >
                    Verhuren
                  </a>
                </li>
                <li
                  className={
                    history.location.pathname.includes("beheer") ? "active" : ""
                  }
                >
                  <a
                    href="/beheer"
                    onClick={() => {
                      onVerhurenChange(false);
                      setSelectedLink("beheer");
                    }}
                  >
                    Beheer
                  </a>
                </li>
              </ul>
            </div>

            <div className="header-top__right">
              <div className="user-profile__icon">
                <Link to="/">
                  <i className="fa fa-user"></i>
                </Link>
              </div>
              <div className="lan-switch">
                <select
                  name="speed"
                  className="select_menu"
                  onChange={(e) => context.toggleLanguage(e.target.value)}
                >
                  <option value="nl">NL</option>
                  <option value="eng">EN</option>
                  <option value="de">DE</option>
                </select>
              </div>
              <div className="search-btn">
                <Link to="/aanbod">
                  <i className="fa fa-search"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!history.location.pathname.includes("beheer") && (
        <div className="header-bottom">
          <div className="container">
            <div className="navigation" id="sticky-header">
              <nav id="primary_menu" className="main_menu">
                {verhuren ? (
                  <ul>
                    <li className={selectedLink === "verhuren" ? "active" : ""}>
                      <Link
                        to="/verhuren"
                        onClick={() => setSelectedLink("verhuren")}
                      >
                        Verhuren
                      </Link>
                    </li>
                    <li
                      className={
                        selectedLink === "verhuren-pakketten" ? "active" : ""
                      }
                    >
                      <Link
                        to="/verhuren-pakketten"
                        onClick={() => setSelectedLink("verhuren-pakketten")}
                      >
                        Pakketten
                      </Link>
                    </li>
                    <li
                      className={
                        selectedLink === "verhuren-over-ons" ? "active" : ""
                      }
                    >
                      <Link
                        to="/verhuren-over-ons"
                        onClick={() => setSelectedLink("verhuren-over-ons")}
                      >
                        Over Ons
                      </Link>
                    </li>
                    <li
                      className={
                        selectedLink === "verhuren-contact" ? "active" : ""
                      }
                    >
                      <Link
                        to="/verhuren-contact"
                        onClick={() => setSelectedLink("verhuren-contact")}
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                ) : (
                  <ul>
                    <li
                      className={
                        window.location.pathname.includes("huren") ||
                        window.location.pathname === "/"
                          ? "active huren"
                          : "huren"
                      }
                    >
                      <a
                        href="/huren"
                        onMouseEnter={() => setMenuOpen(true)}
                        onMouseLeave={() => {
                          setTimeout(() => {
                            if (!touchesMenu) {
                              setMenuOpen(false);
                            }
                          }, 50);
                        }}
                      >
                        Huren
                      </a>
                      {menuOpen && (
                        <div
                          className="city-dropdown"
                          onMouseEnter={() => (touchesMenu = true)}
                          onMouseLeave={() => {
                            touchesMenu = false;
                            setMenuOpen(false);
                          }}
                        >
                          {cities?.map((city) => (
                            <a href={`/huren/${city.toLowerCase()}`}>{city}</a>
                          ))}
                        </div>
                      )}
                    </li>
                    <li
                      className={
                        window.location.pathname.includes("aanbod")
                          ? "active"
                          : ""
                      }
                    >
                      <a href="/aanbod">Aanbod</a>
                    </li>
                    <li
                      className={
                        window.location.pathname.includes("over-ons")
                          ? "active"
                          : ""
                      }
                    >
                      <a href="/over-ons">Over Ons</a>
                    </li>
                    <li
                      className={
                        window.location.pathname.includes("contact")
                          ? "active"
                          : ""
                      }
                    >
                      <a href="/contact">Contact</a>
                    </li>
                  </ul>
                )}
              </nav>
              <div className="mobile-menu dextop_off">
                {/* <a className="logo" href="/">
                  <img src={logo_mobile} alt="BRAND LOGO" />
                </a> */}
              </div>
            </div>
          </div>
        </div>
      )}
      {history.location.pathname.includes("beheer") && (
        <div className="header-bottom">
          <div className="container">
            <div className="navigation" id="sticky-header">
              <nav id="primary_menu" className="main_menu">
                <ul>
                  <li className={selectedLink === "beheer" ? "active" : ""}>
                    <Link
                      to="/beheer"
                      onClick={() => setSelectedLink("beheer")}
                    >
                      Pakketten
                    </Link>
                  </li>
                  <li
                    className={
                      selectedLink === "beheer-over-ons" ? "active" : ""
                    }
                  >
                    <Link
                      to="/beheer-over-ons"
                      onClick={() => setSelectedLink("beheer-over-ons")}
                    >
                      Over Ons
                    </Link>
                  </li>
                  <li
                    className={
                      selectedLink === "beheer-contact" ? "active" : ""
                    }
                  >
                    <Link
                      to="/beheer-contact"
                      onClick={() => setSelectedLink("beheer-contact")}
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </nav>
              <div className="mobile-menu dextop_off">
                {/* <a className="logo" href="/">
                  <img src={logo_mobile} alt="BRAND LOGO" />
                </a> */}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <CityMenu open={showCities} /> */}
    </header>
  );
};
