import React from "react";

export default ({ name, description, image }) => {
  const person = require("../../assets/images/team/team-member-1.jpg");

  return (
    <div className="col-md-4 mt-5">
      <div className="team-member box-shadow">
        <img src={image ? image : person} alt="Team Member" />

        <div className="team-member__info">
          <h5 className="mb-0">{name}</h5>
        </div>
      </div>
      <p style={{ marginTop: "16px" }}>{description}</p>
    </div>
  );
};
