import React, { useEffect } from "react";
import BeheerFeature from "../components/beheer/BeheerFeature";
import BeheerFeature1 from "../components/beheer/BeheerFeature1";
import BeheerFeature2 from "../components/beheer/BeheerFeature2";
import BeheerFeature3 from "../components/beheer/BeheerFeature3";
import BeheerFeature4 from "../components/beheer/BeheerFeature4";
import Start from "../components/contact/Start";
import Hero from "../components/home/Hero";
import Questions from "../components/home/Questions";
import Packages from "../components/verhuren/Packages";

const Beheer = () => {
  const image = require("../assets/images/verhuren-contact__cta.jpg");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Hero
        title="Liever geheel ontzorgd?"
        link="beheer-contact"
        linkText="Of laat ons je adviseren"
        image={image}
      />
      <BeheerFeature />
      <BeheerFeature1 />
      <BeheerFeature2 />
      <BeheerFeature3 />
      <BeheerFeature4 />
      <Packages />
      <Start />
      <Questions />
    </div>
  );
};

export default Beheer;
