import React from "react";

const BeheerFeature = () => {
  return (
    <section
      class="spacing spacing--big keppel_color "
      style={{ padding: "24px" }}
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div
              class="text-lg-left"
              style={{ textAlign: "center", margin: "0 0 32px" }}
            >
              <div class="icon-box icon-box--light">
                <i class="fas fa-users"></i>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="icon-card icon-card__left">
              <div class="icon-card__icon text-white">
                <i class="fas fa-check-circle"></i>
              </div>

              <div class="icon-card__content text-white">
                <h3>2,5 Miljoen Bezoekers</h3>
                <p>
                  Wij zullen ervoor zorgen dat je zo snel mogelijk een huurder
                  vindt. Met ongeveer 2,5 miljoen bezoekers via onze kanalen
                  wordt dit een stuk gemakkelijker en sneller. Om dit proces te
                  versnellen, zullen wij jouw woning ook op onder andere
                  Pararius en Funda plaatsen. Met deze kanalen bereiken wij snel
                  en eenvoudig de juiste huurders. Hierdoor wordt de kans nog
                  groter dat jouw woning snel verhuurd wordt.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BeheerFeature;
