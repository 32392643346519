import React from "react";
import { Link } from "react-router-dom";

export default ({ details }) => {
  const getDescription = () => {
    const description =
      details?.description_nl ||
      details?.description_de ||
      details?.description_eng;
    return description?.replace(/\n/g, "<br/>");
  };

  return (
    <div className="row">
      <div className="col-lg-8">
        <div className="row">
          <div className="col-lg-3">
            <div className="icon-card">
              <div className="icon-card__icon box-shadow">
                <i className="fas fa-vector-square"></i>
              </div>

              <div className="icon-card__content icon-card__content--dark">
                <h4>Oppervlakte</h4>
                <p>{`${details?.characteristics[0]?.surface}m2`}</p>
              </div>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="icon-card">
              <div className="icon-card__icon box-shadow">
                <i className="fas fa-door-open"></i>
              </div>

              <div className="icon-card__content icon-card__content--dark">
                <h4>Aantal Kamers</h4>
                <p>{`${details?.characteristics[0]?.total_rooms} Kamers`}</p>
              </div>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="icon-card">
              <div className="icon-card__icon box-shadow">
                <i className="fab fa-fort-awesome"></i>
              </div>

              <div className="icon-card__content icon-card__content--dark">
                <h4>Type Woning</h4>
                <p>
                  {details?.characteristics[0]?.house_type_nl ||
                    details?.characteristics[0]?.house_type_eng ||
                    details?.characteristics[0]?.house_type_de}
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="icon-card">
              <div className="icon-card__icon box-shadow">
                <i className="fas fa-couch"></i>
              </div>

              <div className="icon-card__content icon-card__content--dark">
                <h4>Conditie</h4>
                <p>
                  {details?.characteristics[0]?.condition_nl ||
                    details?.characteristics[0]?.condition_eng ||
                    details?.characteristics[0]?.condition_de}
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div className="row">
          <div className="col-md-12">
            <div className="text-block">
              <h3>Omschrijving</h3>
              <p dangerouslySetInnerHTML={{ __html: getDescription() }} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="right-sidebar">
          <h2>Kenmerken</h2>

          <div className="sidebar-widget search-filter-widget">
            <h4>Oppervlakten & Inhoud</h4>

            <ul className="widget-list">
              <li>
                <Link to="">Wonen</Link>{" "}
                <span>{`${details?.characteristics[0]?.surface}m2`}</span>
              </li>
              <li>
                <Link to="">Gebouwgebonden buitenruimte</Link>{" "}
                <span>{`${
                  details?.characteristics[0]?.outside_space || 0
                }m2`}</span>
              </li>
              <li>
                <Link to="">Externe bergruimte</Link>{" "}
                <span>{`${
                  details?.characteristics[0]?.storage_space || 0
                }m2`}</span>
              </li>
              <li>
                <Link to="">Inhoud</Link>{" "}
                <span>{`${
                  details?.characteristics[0]?.property_content || 0
                }m2`}</span>
              </li>
            </ul>
          </div>
          <div className="sidebar-widget search-filter-widget">
            <h4>Indeling</h4>

            <ul className="widget-list">
              <li>
                <Link to="">Aantal Kamers</Link>{" "}
                <span>{`${details?.characteristics[0]?.total_rooms} Kamers`}</span>
              </li>
              <li>
                <Link to="">Aantal Badkamers</Link>{" "}
                <span>{`${details?.characteristics[0]?.bathrooms} Badkamer`}</span>
              </li>
              <li>
                <Link to="">Aantal Woonlagen</Link>{" "}
                <span>{`${details?.characteristics[0]?.residential_layers} Woonlaag`}</span>
              </li>
              <li>
                <Link to="">Gelegen op</Link>{" "}
                <span>
                  {details?.characteristics[0]?.located_on_nl ||
                    details?.characteristics[0]?.located_on_eng ||
                    details?.characteristics[0]?.located_on_de}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
