import React, { useEffect } from "react";
import Hero from "../components/verhuren/Hero";
import Packages from "../components/verhuren/Packages";
import Questions from "../components/home/Questions";

export default () => {
  const image = require("../assets/images/cta-bg.jpg");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Hero
        title="Kies het pakket dat bij jou past"
        link="verhuren-contact"
        linktext="Of laat ons je adviseren"
        image={image}
      />
      <Packages title />
      <Questions blue />
    </div>
  );
};
