import { createSlice } from "@reduxjs/toolkit";

export const housesSlice = createSlice({
  name: "houses",
  initialState: {
    value: [],
    selectedFilters: {
      distance: 10,
      minimum: 200,
      maximum: 2500,
      location: "Groningen",
      search: "",
      house_type: [],
      condition: [],
      available: [],
    },
  },
  reducers: {
    setHouses: (state, houses) => {
      state.value = houses.payload;
    },
    setSelectedFilters: (state, filters) => {
      state.selectedFilters = filters.payload;
    },
  },
});

export const { setHouses, setSelectedFilters } = housesSlice.actions;

export const selectHouses = (state) => state.houses.value;
export const selectFilters = (state) => state.houses.selectedFilters;

export default housesSlice.reducer;
