import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCities } from "../../api/pages/Pages";
import {
  selectFilters,
  setSelectedFilters,
} from "../../features/houses/housesReducer";

export default ({ location }) => {
  const filters = useSelector(selectFilters);
  const dispatch = useDispatch();
  const [cities, setCities] = useState();

  useEffect(() => {
    retrieveCities();
  }, [filters]);

  const history = useHistory();

  const retrieveCities = async () => {
    const result = await getCities();
    setCities(result.map((r) => r.city));
  };

  return (
    <section id="search-area">
      <div className="container">
        <div className="search-filter-wrap">
          <form
            onChange={(e) => {
              let newFilter = {};
              newFilter[e.target.name] = e.target.value;
              dispatch(setSelectedFilters({ ...filters, ...newFilter }));
            }}
          >
            <div>
              <p>Plaats</p>
              <select
                name="location"
                className="select_menu"
                value={location || filters?.location}
              >
                {cities?.map((city) => (
                  <option value={city}>{city}</option>
                ))}
              </select>
            </div>

            <div>
              <p>Prijs Vanaf</p>
              <select
                name="minimum"
                className="select_menu"
                value={filters?.minimum}
              >
                {[
                  { value: 0, name: "Geen minimum" },
                  { value: 100, name: "€100" },
                  { value: 200, name: "€200" },
                  { value: 300, name: "€300" },
                  { value: 400, name: "€400" },
                  { value: 500, name: "€500" },
                  { value: 600, name: "€600" },
                  { value: 700, name: "€700" },
                  { value: 800, name: "€800" },
                  { value: 900, name: "€900" },
                  { value: 1000, name: "€1000" },
                  { value: 1100, name: "€1100" },
                  { value: 1200, name: "€1200" },
                  { value: 1300, name: "€1300" },
                  { value: 1400, name: "€1400" },
                  { value: 1500, name: "€1500" },
                  { value: 1600, name: "€1600" },
                  { value: 1700, name: "€1700" },
                  { value: 1800, name: "€1800" },
                  { value: 1900, name: "€1900" },
                  { value: 2000, name: "€2000" },
                  { value: 2100, name: "€2100" },
                  { value: 2200, name: "€2200" },
                  { value: 2300, name: "€2300" },
                  { value: 2400, name: "€2400" },
                ].map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <p>Tot</p>
              <select
                name="maximum"
                className="select_menu"
                value={filters?.maximum}
              >
                {[
                  { value: 300, name: "€300" },
                  { value: 400, name: "€400" },
                  { value: 500, name: "€500" },
                  { value: 600, name: "€600" },
                  { value: 700, name: "€700" },
                  { value: 800, name: "€800" },
                  { value: 900, name: "€900" },
                  { value: 1000, name: "€1000" },
                  { value: 1100, name: "€1100" },
                  { value: 1200, name: "€1200" },
                  { value: 1300, name: "€1300" },
                  { value: 1400, name: "€1400" },
                  { value: 1500, name: "€1500" },
                  { value: 1600, name: "€1600" },
                  { value: 1700, name: "€1700" },
                  { value: 1800, name: "€1800" },
                  { value: 1900, name: "€1900" },
                  { value: 2000, name: "€2000" },
                  { value: 2100, name: "€2100" },
                  { value: 2200, name: "€2200" },
                  { value: 2300, name: "€2300" },
                  { value: 2400, name: "€2400" },
                  { value: 2500, name: "€2500" },
                  { value: 99999, name: "Geen maximum" },
                ].map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <button
                type="submit"
                onClick={(e) => {
                  history.push("/aanbod");
                  e.preventDefault();
                }}
              >
                Zoeken
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};
