import React, { useCallback, useContext, useEffect, useState } from "react";
import { getTeam } from "../../api/pages/Pages";
import { LanguageContext } from "../../context/LanguageContext";
import TeamMember from "./TeamMember";

export default () => {
  const { language } = useContext(LanguageContext);
  const [team, setTeam] = useState();

  const doRequest = useCallback(async () => {
    const team = await getTeam(language);
    if (team) {
      setTeam(team[0]);
    }
  }, [language]);

  useEffect(() => {
    doRequest();
  }, [doRequest]);

  return (
    <section id="our-team" className="spacing">
      <div className="container">
        <div className="section-header text-center">
          <h3>{team?.title_nl || team?.title_de || team?.title_eng}</h3>
          <h6>
            {team?.description_nl ||
              team?.description_de ||
              team?.description_eng}
          </h6>
        </div>

        <div className="row" style={{ justifyContent: "center" }}>
          {team?.team_members.map((member) => (
            <TeamMember
              name={member.name}
              image={`${process.env.REACT_APP_BASE_URL}${member.photo}`}
              description={
                member.description_nl ||
                member.description_de ||
                member.description_eng
              }
            />
          ))}
        </div>
      </div>
    </section>
  );
};
