import React from "react";
import { Link } from "react-router-dom";

export default () => {
  return (
    <section id="ask-question" class="spacing">
      <div class="container">
        <div class="section-header text-center">
          <h3>Ga direct naar</h3>
        </div>

        <div class="row">
          <div class="col-lg-4">
            <div class="icon-card box-shadow icon-card--2">
              <div class="icon-card__icon icon-card__icon--2">
                <i class="fas fa-home"></i>
              </div>

              <Link class="icon-card__content" to="/">
                <h4>Terug naar home</h4>
                <p style={{ padding: "20px" }}>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </Link>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="icon-card box-shadow icon-card--2">
              <div class="icon-card__icon icon-card__icon--2">
                <i class="fas fa-user-friends" aria-hidden="true"></i>
              </div>

              <Link class="icon-card__content" to="/over-ons">
                <h4>over ons</h4>
                <p style={{ padding: "20px" }}>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </Link>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="icon-card box-shadow icon-card--2">
              <div class="icon-card__icon icon-card__icon--2">
                <i class="far fa-envelope" aria-hidden="true"></i>
              </div>

              <Link class="icon-card__content" to="/contact">
                <h4>of neem contact op</h4>
                <p style={{ padding: "20px" }}>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
