import React from "react";
import { Link, useLocation } from "react-router-dom";

export default () => {
  const location = useLocation();

  return (
    <section id="verhuren-step" className="spacing">
      <div className="container">
        <div className="text-center">
          <h3 className="text-white">Verhuren in 3 stappen</h3>
        </div>

        <div className="card-deck">
          <div className="card icon-card icon-card--left icon-card--box icon-card__dark box-shadow border-0">
            <div className="icon-card__icon icon-card__icon--sm keppel_color">
              <span>1</span>
            </div>
            <div className="card-body p-0">
              <div className="icon-card__content">
                <h4>Bedenk wat je wil</h4>
                <p>
                  Wil je dat wij je woning fotograferen, stylen en op onze
                  kanalen plaatsen of wil je ook graag dat wij het contact met
                  potentiële huurders verzorgen en de bezichtigingen op ons
                  nemen? Dat kan allemaal!
                </p>
              </div>
            </div>
          </div>

          <div className="card icon-card icon-card--left icon-card--box icon-card__dark box-shadow border-0">
            <div className="icon-card__icon icon-card__icon--sm keppel_color">
              <span>2</span>
            </div>
            <div className="card-body p-0">
              <div className="icon-card__content">
                <h4>Bepaal je pakket</h4>
                <p>
                  Aan de hand van de punten die voor jou belangrijk zijn bepaal
                  je welk pakket het best bij jou past. Kom je er niet helemaal
                  uit? Laat het ons dan weten, we helpen je graag.
                </p>
              </div>
            </div>
          </div>

          <div className="card icon-card icon-card--left icon-card--box icon-card__dark box-shadow border-0">
            <div className="icon-card__icon icon-card__icon--sm keppel_color">
              <span>3</span>
            </div>
            <div className="card-body p-0">
              <div className="icon-card__content">
                <h4>Laat ons de rest doen</h4>
                <p>
                  Wanneer je het pakket hebt gekozen dat bij jou past zorgen wij
                  voor de rest. Hierin blijven we uiteraard met jou in contact
                  zodat je altijd inzage hebt in wat wij voor jou doen.
                </p>
              </div>
            </div>
          </div>
        </div>
        {location.pathname !== "/verhuren" && (
          <div className="text-center mt-5">
            <Link className="readmore-btn text-white" to="/verhuren">
              Lees meer over verhuren
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};
