import React from "react";
import { Link } from "react-router-dom";

export default () => {
  const asset = require("../../assets/images/image-1.png");

  return (
    <section id="quote" className="spacing">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-5">
            <div className="quotes-image">
              <img src={asset} alt="quotes" />
            </div>
          </div>

          <div className="col-lg-5">
            <div className="quotes-content">
              <h3 className="quotes-title">
                Door LTWonen het beheer in handen te geven hoef ik mij geen
                zorgen meer te maken over wie er in mijn woning komt.
              </h3>
              <p>Lisa laat LTWonen haar woning beheren.</p>

              <div className="wt-btn">
                <a href="/beheer">Ga Naar Beheer</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
