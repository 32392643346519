import React from "react";

export default ({ homePage }) => {
  const asset1 = require("../../assets/images/ellipse-icon/asset-2_1.png");
  const asset2 = require("../../assets/images/ellipse-icon/asset-2_2.png");
  const asset3 = require("../../assets/images/ellipse-icon/asset-2_3.png");

  const getTranslatedTitle = () => {
    const title =
      homePage?.title_nl || homePage?.title_de || homePage?.title_eng;
    return title?.replace(/\n/g, "<br/>");
  };

  const getTranslatedText = () => {
    const text = homePage?.text_nl || homePage?.text_de || homePage?.text_eng;
    return text?.replace(/\n/g, "<br/>");
  };

  return (
    <section id="huurwoningen" className="spacing ellipse_icons--shape">
      <div className="ellipse_icons ellipse_icons--2">
        <img src={asset1} alt="asset1" />
        <img src={asset2} alt="asset2" />
        <img src={asset3} alt="asset3" />
      </div>

      <div className="container">
        <div className="huurwoningen-content__box box-shadow">
          <h2
            dangerouslySetInnerHTML={{ __html: getTranslatedTitle() }}
            style={{ textAlign: "start", margin: "0 64px 16px" }}
          />
          <p
            dangerouslySetInnerHTML={{ __html: getTranslatedText() }}
            style={{ textAlign: "start", margin: "auto 64px" }}
          />
        </div>
      </div>
    </section>
  );
};
