import React, { useCallback, useContext, useEffect, useState } from "react";
import { getUSPs } from "../../api/pages/Pages";
import { LanguageContext } from "../../context/LanguageContext";

export default ({ title }) => {
  const { language } = useContext(LanguageContext);

  const [usps, setUsps] = useState();

  const update = useCallback(async () => {
    const usps = await getUSPs(language);
    setUsps(usps?.slice(3, 6));
  }, [language]);

  useEffect(() => {
    update();
  }, [update]);

  return (
    <section id="huren" className="spacing huren--light">
      <div className="container">
        {title && (
          <h3 className="text-center mb-5">
            Waarom zou jij gebruik maken van LTWonen?
          </h3>
        )}
        <h5 style={{ fontWeight: 300, textAlign: "center" }}>
          Waarom zou jij gebruik maken van LTWonen? Bij LTWonen gaat het net
          even anders dan bij de doorsnee makelaar. <br />
          Dat komt omdat het persoonlijke contact bij ons bovenaan staat. Wij
          kunnen daardoor nòg beter afstemmen op wat klanten van ons willen. De
          lijntjes zijn kort en de acties zijn snel, omdat we zelfstandig onze
          beslissingen kunnen nemen. En: wij staan voor transparante
          communicatie en een eerlijke prijs. What you see is what you get!
          <br /> <br />
          <strong>Wat LTWonen kan bieden</strong> <br />
          <br />
          <div className="listitem-root">
            <ul className="listitems">
              <li>een platform, voor woningzoekenden èn -aanbieders</li>
              <li>
                hulp bij het formuleren van wensen voor woningzoekenden en
                -aanbieders
              </li>
              <li>hulp bij de zoektocht naar de meest geschikte woonruimte </li>
              <li>contact met verhuurder / huurder </li>
              <li>styling en fotografie </li>
              <li>
                bezichtiging bij oplevering en voor aanvaarding
                sleuteloverdracht
              </li>
              <li>adverteren en presentatie via social media </li>
              <li>onafhanklijke bouwkundig technische rapportage </li>
              <li>
                bemiddeling bij administratieve, notariële en financiële
                afhandeling
              </li>
              <li>beheer woonruimtes</li>
            </ul>
          </div>
          <strong>Formuleer jouw wensenpakket, laat ons de rest doen!</strong>
        </h5>

        <div className="row huren--light__border">
          {usps?.map((usp) => (
            <div className="col-lg-4">
              <div className="icon-card">
                <div className="icon-card__icon keppel_color box-shadow">
                  <img
                    alt="USP Icon"
                    src={`${process.env.REACT_APP_BASE_URL}${usp.icon}`}
                    style={{ filter: "brightness(10000%)" }}
                  />
                </div>

                <div className="icon-card__content">
                  <h4>{usp.title_nl || usp.title_de || usp.title_eng}</h4>
                  <p>{usp.text_nl || usp.text_de || usp.text_eng}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
