import React, { useState } from "react";
import Image from "react-image-enlarger";

export default ({ images }) => {
  const [imageOpen, setImageOpen] = useState();

  return (
    <div className="row">
      <div className="col-lg-8">
        <div className="image-card">
          <Image
            src={images && images[0]}
            alt=""
            onClick={() => setImageOpen(1)}
            onRequestClose={() => setImageOpen(0)}
            zoomed={imageOpen === 1}
          />
        </div>

        <hr />
      </div>
      <div className="col-lg-4">
        <div className="row">
          <div className="col-md-12">
            <div className="image-card">
              <Image
                src={images && images[1]}
                alt=""
                onClick={() => setImageOpen(2)}
                onRequestClose={() => setImageOpen(0)}
                zoomed={imageOpen === 2}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="image-card">
              <Image
                src={images && images[2]}
                alt=""
                onClick={() => setImageOpen(3)}
                onRequestClose={() => setImageOpen(0)}
                zoomed={imageOpen === 3}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="image-card">
              <Image
                src={images && images[3]}
                alt=""
                onClick={() => setImageOpen(4)}
                onRequestClose={() => setImageOpen(0)}
                zoomed={imageOpen === 4}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="image-card">
              <Image
                src={images && images[4]}
                alt=""
                onClick={() => setImageOpen(5)}
                onRequestClose={() => setImageOpen(0)}
                zoomed={imageOpen === 5}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="image-card">
              <Image
                src={images && images[5]}
                alt=""
                onClick={() => setImageOpen(6)}
                onRequestClose={() => setImageOpen(0)}
                zoomed={imageOpen === 6}
              />
            </div>
          </div>
        </div>

        <hr />
      </div>
    </div>
  );
};
