import Api from "../Api";

const getParams = (lang) => {
  switch (lang) {
    case "nl":
      return { nl: 1 };
    case "eng":
      return { eng: 1 };
    case "de":
      return { de: 1 };
    default:
      break;
  }
};

export const getPackages = async (language) => {
  const request = Api.get(`get/packages/`, {
    params: getParams(language),
  });
  try {
    return (await request).data;
  } catch (error) {
    console.error(error.message);
    return null;
  }
};

export const sendApplyMail = async (
  pkg,
  firstname,
  lastname,
  email,
  tel,
  bericht,
  extra
) => {
  const request = Api.post(`aanvragen/`, {
    data: {
      first_name: firstname,
      last_name: lastname,
      package: pkg,
      mail: email,
      tel,
      bericht,
      extra,
    },
  });
  try {
    return (await request).data;
  } catch (error) {
    console.error(error.message);
    return null;
  }
};
