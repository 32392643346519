import React from "react";
import { Link } from "react-router-dom";

const ListItem = ({ house }) => {
  return (
    <div className="row product-box product-box__list box-shadow no-gutters p-3">
      <div className="col-md-4">
        <div className="product-image">
          {house.new && (
            <span className="badge new-product box-shadow">Nieuw</span>
          )}
          <div
            className="product-box__bg"
            style={{
              height: "155px",
              minHeight: 0,
            }}
          >
            <Link
              to={`/detail/${house.zipcode}/${house.house_number}/${house.apartment}`}
            >
              <img
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                }}
                src={`${process.env.REACT_APP_BASE_URL}${house.preview_image}`}
                alt="product-box__bg"
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="col-md-8">
        <div className="product-box__content">
          <h2 className="product-box__title--2">
            <Link
              to={`/detail/${house.zipcode}/${house.house_number}/${house.apartment}`}
            >{`${house.street} ${house.house_number}${
              house.apartment &&
              (isNaN(Number(house.apartment))
                ? house.apartment
                : " - " + house.apartment)
            }`}</Link>
          </h2>
          <p>{`${house.zipcode} ${house.city.toUpperCase()}`}</p>

          <div className="product-box__bottom">
            <ul className="product-tags-search">
              <li>{`${house.characteristics?.[0]?.surface} m`}</li>
              <li>{`${house.characteristics?.[0]?.total_rooms} kamers`}</li>
              <li>{`${house.neighborhood}`}</li>
              <li>{`${
                house.characteristics?.[0]?.condition_nl ||
                house.characteristics?.[0]?.condition_eng ||
                house.characteristics?.[0]?.condition_de
              }`}</li>
            </ul>
            <h5 className="product-price">{`€${house.rent} /mnd`}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListItem;
