import React, { useCallback, useContext, useEffect, useState } from "react";
import Aanbod from "../components/home/Aanbod";
import Subscribe from "../components/home/Subscribe";
import Hero from "../components/home/Hero";
import Text from "../components/home/Text";
import SearchBar from "../components/home/SearchBar";
import USPs from "../components/home/USPs";
import Questions from "../components/home/Questions";
import { searchHouses } from "../api/houses/Houses";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFilters,
  selectHouses,
  setHouses,
  setSelectedFilters,
} from "../features/houses/housesReducer";
import { getCityHeader, getHomePage, getUSPs } from "../api/pages/Pages";
import { LanguageContext } from "../context/LanguageContext";
import { useParams } from "react-router-dom";

export default () => {
  const { language } = useContext(LanguageContext);
  const { city } = useParams();

  const dispatch = useDispatch();
  const houses = useSelector(selectHouses);
  const filters = useSelector(selectFilters);

  const [cityHeader, setCityHeader] = useState();
  const [homePage, setHomePage] = useState();
  const [usps, setUsps] = useState();

  const search = useCallback(async () => {
    const result = await searchHouses(filters?.location, 300, 100);
    if (typeof result === "object") dispatch(setHouses(result));
  }, [dispatch, city, filters]);

  const updateHomePage = useCallback(async () => {
    const cityHeader = await getCityHeader(filters?.location);
    setCityHeader(cityHeader);
    const page = await getHomePage(
      language,
      city ? city[0].toUpperCase() + city.substring(1) : "Homepage"
    );
    setHomePage(page);
    const usps = await getUSPs(language);
    setUsps(usps?.slice(0, 3));
  }, [language, filters]);

  useEffect(() => {
    if (city) {
      dispatch(
        setSelectedFilters({
          ...filters,
          location: city[0].toUpperCase() + city.substring(1),
        })
      );
    }
  }, [city]);

  useEffect(() => {
    search();
  }, [search]);

  useEffect(() => {
    window.scrollTo(0, 0);
    updateHomePage();
  }, [language, updateHomePage]);

  return (
    <div>
      <Hero
        linkText="Of verhuur je woning"
        link="/verhuren"
        title="Ga op zoek naar de mooiste huizen om te huren"
        image={
          cityHeader?.[0] &&
          `${process.env.REACT_APP_BASE_URL}${cityHeader?.[0].image}`
        }
      />
      <SearchBar location={filters?.location} />
      <Aanbod houses={houses} />
      <Text homePage={homePage} />
      <USPs textColor="#FFFFFF" usps={usps} />
      <Questions />
      <Subscribe />
    </div>
  );
};
