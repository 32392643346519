import Api from "../Api";

const getParams = (lang) => {
  switch (lang) {
    case "nl":
      return { nl: 1 };
    case "eng":
      return { eng: 1 };
    case "de":
      return { de: 1 };
    default:
      break;
  }
};

export const getHomePage = async (language, city) => {
  const request = Api.get(`get/homepage/`, {
    params: {
      ...getParams(language),
      city,
    },
  });
  try {
    return (await request).data;
  } catch (error) {
    console.error(error.message);
    return null;
  }
};

export const getCityHeader = async (city) => {
  const request = Api.get(`get/cityheader/`, {
    params: {
      city,
    },
  });
  try {
    return (await request).data;
  } catch (error) {
    console.error(error.message);
    return null;
  }
};

export const getCities = async () => {
  const request = Api.get(`get/cities/`);
  try {
    return (await request).data;
  } catch (error) {
    console.error(error.message);
    return null;
  }
};

export const getTeam = async (language) => {
  const request = Api.get(`get/team/`, {
    params: getParams(language),
  });
  try {
    return (await request).data;
  } catch (error) {
    console.error(error.message);
    return null;
  }
};

export const getUSPs = async (language) => {
  const request = Api.get(`get/prousp/`, {
    params: getParams(language),
  });
  try {
    return (await request).data;
  } catch (error) {
    console.error(error.message);
    return null;
  }
};

export const registerEmail = async (email) => {
  return Api.post(`subscriber/save/`, {
    email,
  });
};

export const filterHouses = async (filters, language) => {
  const request = Api.get(`get/filter/`, {
    params: {
      ...getParams(language),
      city: filters.city,
      rent__gte: filters.minimum,
      rent__lte: filters.maximum,
      no_cost_renter: filters.no_cost_renter ? "True" : null,
      service_cost_included: filters.service_cost_included ? "True" : "False",
      bedrooms: filters.bedrooms,
      surface: filters.surface,
      house_type: filters.house_type,
      condition: filters.condition,
      available: filters.available,
    },
  });
  try {
    return (await request).data;
  } catch (error) {
    console.error(error.message);
    return null;
  }
};

export const getMeta = async (url) => {
  const request = Api.get(`get/pagemeta/`, {
    params: {
      url,
    },
  });
  try {
    return (await request).data;
  } catch (error) {
    console.error(error.message);
    return null;
  }
};

export const getBrokenLinks = async () => {
  const request = Api.get(`get/brokenlink/`);
  try {
    return (await request).data;
  } catch (error) {
    console.error(error.message);
    return null;
  }
};
