import React from "react";

export default ({ title, options, onChange }) => {
  return (
    <div className="sidebar-widget search-filter-widget">
      <h4>{title}</h4>
      <form action="" className="form-widget">
        {options.map((option, index) => (
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              checked={option.value}
              onChange={(e) => {
                let obj = {};
                if (option.setter) {
                  if (typeof option.setter === "object") {
                    obj[option.id] = option.setter;
                  } else {
                    obj[option.id] = e.target.checked ? option.setter : null;
                  }
                } else {
                  obj[option.id] = e.target.checked;
                }
                onChange(obj);
              }}
              id={option.name}
            />
            <label className="custom-control-label" for={option.name}>
              {option.name}
            </label>
            {/* <span className="count-number">{option.amount}</span> */}
          </div>
        ))}
      </form>
      {/* <Link className="load-btn">+ Toon alle opties</Link> */}
    </div>
  );
};
