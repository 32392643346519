import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchHouse } from "../api/houses/Houses";
import { filterHouses } from "../api/pages/Pages";
import FilterWidget from "../components/offer/FilterWidget";
import ListItem from "../components/offer/ListItem";
import PriceWidget from "../components/offer/PriceWidget";
import SearchArea from "../components/offer/SearchArea";
import { LanguageContext } from "../context/LanguageContext";
import {
  selectFilters,
  setSelectedFilters,
} from "../features/houses/housesReducer";
import { useMediaQuery } from "react-responsive";

export default () => {
  const [listType, setListType] = useState("list");
  const filters = useSelector(selectFilters);
  const dispatch = useDispatch();

  const { language } = useContext(LanguageContext);
  const [result, setResult] = useState();
  const [filtersOpen, setFiltersOpen] = useState(true);

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 991px)",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updateHouses = useCallback(async () => {
    let result;
    if (filters.search) {
      result = await searchHouse(filters.search, language);
    } else {
      result = await filterHouses(filters, language);
      result = result?.results;
    }
    setResult(result);
  }, [filters, language]);

  useEffect(() => {
    updateHouses();
  }, [updateHouses]);

  return (
    <div>
      <section id="page-banner" className="spacing aanbod-page">
        <div className="container"></div>
      </section>
      <SearchArea />
      <section id="page-content" className="spacing">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="sidebar sidebar--left">
                <PriceWidget
                  amount={result?.length}
                  onTypeChange={setListType}
                  selectedType={listType}
                />
                {isTabletOrMobileDevice && (
                  <button onClick={() => setFiltersOpen(!filtersOpen)}>
                    Filters
                  </button>
                )}
                {(!isTabletOrMobileDevice || filtersOpen) && (
                  <div>
                    <FilterWidget
                      title="Kosten Huurder"
                      options={[
                        {
                          name: "Geen Kosten Huurder",
                          value: filters.no_cost_renter,
                          id: "no_cost_renter",
                        },
                        {
                          name: "Inclusief Servicekosten",
                          value: filters.service_cost_included,
                          id: "service_cost_included",
                        },
                      ]}
                      onChange={(e) =>
                        dispatch(setSelectedFilters({ ...filters, ...e }))
                      }
                    />
                    <FilterWidget
                      title="Aantal Slaapkamers"
                      options={[
                        {
                          name: "1 Kamer",
                          value: filters.bedrooms === 1,
                          id: "bedrooms",
                          setter: 1,
                        },
                        {
                          name: "2 Kamer",
                          value: filters.bedrooms === 2,
                          id: "bedrooms",
                          setter: 2,
                        },
                        {
                          name: "3 Kamer",
                          value: filters.bedrooms === 3,
                          id: "bedrooms",
                          setter: 3,
                        },
                        {
                          name: "4 Kamer",

                          id: "bedrooms",
                          setter: 4,
                        },
                        {
                          name: "4+ Kamer",
                          value: filters.bedrooms === 5,
                          id: "bedrooms",
                          setter: 5,
                        },
                      ]}
                      onChange={(e) => {
                        dispatch(setSelectedFilters({ ...filters, ...e }));
                      }}
                    />
                    <FilterWidget
                      title="Oppervlakte"
                      options={[
                        {
                          name: "30+ m2",
                          value: filters.surface === 30,
                          id: "surface",
                          setter: 30,
                        },
                        {
                          name: "40+ m2",
                          value: filters.surface === 40,
                          id: "surface",
                          setter: 40,
                        },
                        {
                          name: "50+ m2",
                          value: filters.surface === 50,
                          id: "surface",
                          setter: 50,
                        },
                        {
                          name: "60+ m2",
                          value: filters.surface === 60,
                          id: "surface",
                          setter: 60,
                        },
                      ]}
                      onChange={(e) => {
                        dispatch(setSelectedFilters({ ...filters, ...e }));
                      }}
                    />
                    <FilterWidget
                      title="Type Woning"
                      options={[
                        {
                          name: "Studio",
                          value: filters.house_type?.includes("Studio"),
                          id: "house_type",
                          setter: filters.house_type?.includes("Studio")
                            ? filters.house_type?.filter((h) => h !== "Studio")
                            : [
                                ...(filters.house_type
                                  ? filters.house_type
                                  : []),
                                "Studio",
                              ],
                        },
                        {
                          name: "Appartement",
                          value: filters.house_type?.includes("Appartement"),
                          id: "house_type",
                          setter: filters.house_type?.includes("Appartement")
                            ? filters.house_type?.filter(
                                (h) => h !== "Appartement"
                              )
                            : [
                                ...(filters.house_type
                                  ? filters.house_type
                                  : []),
                                "Appartement",
                              ],
                        },
                        {
                          name: "Woonhuis",
                          value: filters.house_type?.includes("Woonhuis"),
                          id: "house_type",
                          setter: filters.house_type?.includes("Woonhuis")
                            ? filters.house_type?.filter(
                                (h) => h !== "Woonhuis"
                              )
                            : [
                                ...(filters.house_type
                                  ? filters.house_type
                                  : []),
                                "Woonhuis",
                              ],
                        },
                      ]}
                      onChange={(e) => {
                        dispatch(setSelectedFilters({ ...filters, ...e }));
                      }}
                    />
                    <FilterWidget
                      title="Beschikbaarheid"
                      options={[
                        {
                          name: "Direct Beschikbaar",
                          value:
                            filters.available?.includes("Direct Beschikbaar"),
                          id: "available",
                          setter: filters.available?.includes(
                            "Direct Beschikbaar"
                          )
                            ? filters.available?.filter(
                                (h) => h !== "Direct Beschikbaar"
                              )
                            : [
                                ...(filters.available ? filters.available : []),
                                "Direct Beschikbaar",
                              ],
                        },
                        {
                          name: "1 maand",
                          value: filters.available?.includes("1 maand"),
                          id: "available",
                          setter: filters.available?.includes("1 maand")
                            ? filters.available?.filter((h) => h !== "1 maand")
                            : [
                                filters.available ? filters.available : [],
                                "1 maand",
                              ],
                        },
                        {
                          name: "2 maanden",
                          value: filters.available?.includes("2 maanden"),
                          id: "available",
                          setter: filters.available?.includes("2 maanden")
                            ? filters.available?.filter(
                                (h) => h !== "2 maanden"
                              )
                            : [
                                filters.available ? filters.available : [],
                                "2 maanden",
                              ],
                        },
                        {
                          name: "3 maanden",
                          value: filters.available?.includes("3 maanden"),
                          id: "available",
                          setter: filters.available?.includes("3 maanden")
                            ? filters.available?.filter(
                                (h) => h !== "3 maanden"
                              )
                            : [
                                filters.available ? filters.available : [],
                                "3 maanden",
                              ],
                        },
                      ]}
                      onChange={(e) => {
                        dispatch(setSelectedFilters({ ...filters, ...e }));
                      }}
                    />
                    <FilterWidget
                      title="Huurcondities"
                      options={[
                        {
                          name: "Kaal",
                          value: filters.condition?.includes("Kaal"),
                          id: "condition",
                          setter: filters.condition?.includes("Kaal")
                            ? filters.condition?.filter((h) => h !== "Kaal")
                            : [
                                ...(filters.condition ? filters.condition : []),
                                "Kaal",
                              ],
                        },
                        {
                          name: "Gemeubileerd",
                          value: filters.condition?.includes("Gemeubileerd"),
                          id: "condition",
                          setter: filters.condition?.includes("Gemeubileerd")
                            ? filters.condition?.filter(
                                (h) => h !== "Gemeubileerd"
                              )
                            : [
                                ...(filters.condition ? filters.condition : []),
                                "Gemeubileerd",
                              ],
                        },
                        {
                          name: "Gestoffeerd",
                          value: filters.condition?.includes("Gestoffeerd"),
                          id: "condition",
                          setter: filters.condition?.includes("Gestoffeerd")
                            ? filters.condition?.filter(
                                (h) => h !== "Gestoffeerd"
                              )
                            : [
                                ...(filters.condition ? filters.condition : []),
                                "Gestoffeerd",
                              ],
                        },
                      ]}
                      onChange={(e) => {
                        dispatch(setSelectedFilters({ ...filters, ...e }));
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-9">
              <div className="main-content">
                {result?.map((house) => (
                  <ListItem house={house} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
