import React, { useEffect } from "react";
import Questions from "../components/home/Questions";
import Hero from "../components/verhuren/Hero";
import Help from "../components/verhurenContact/Help";

export default () => {
  const image = require("../assets/images/contact-bg.jpg");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Hero title="Waar kunnen we je mee helpen?" image={image} />
      <Questions />
      <Help />
    </div>
  );
};
