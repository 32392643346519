import Axios from "axios";
import Api from "../Api";

const CancelToken = Axios.CancelToken;

const getParams = (lang) => {
  switch (lang) {
    case "nl":
      return { nl: 1 };
    case "eng":
      return { eng: 1 };
    case "de":
      return { de: 1 };
    default:
      break;
  }
};

export const getHouse = async (zipcode, house_number, appartment, language) => {
  const request = Api.get(`get/detailhouse/`, {
    params: {
      ...getParams(language),
      zipcode,
      house_number,
      appartment,
    },
  });
  try {
    return (await request).data;
  } catch (error) {
    console.error(error.message);
    return null;
  }
};

export const searchHouse = async (search, language) => {
  const request = Api.get(`get/search/`, {
    params: {
      ...getParams(language),
      q: search,
    },
  });
  try {
    return (await request).data;
  } catch (error) {
    console.error(error.message);
    return null;
  }
};

export const searchHouses = async (city, rent__gte, rent__lte, setCancel) => {
  const request = Api.get("get/houses/", {
    params: {
      city,
      rent__gte,
      rent__lte,
    },
    cancelToken: setCancel && new CancelToken(setCancel),
  });
  try {
    return (await request).data;
  } catch (error) {
    console.error(error.message);
    return [];
  }
};
