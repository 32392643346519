import React from "react";

const BeheerFeature = () => {
  return (
    <section class="spacing spacing--big" style={{ padding: "24px" }}>
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="icon-card icon-card__left">
              <div class="icon-card__icon">
                <i class="fas fa-check-circle"></i>
              </div>

              <div class="icon-card__content text-dark">
                <h3>Alles uit handen</h3>
                <p>
                  Met het ‘compleet’ pakket nemen wij alle aspecten van het
                  verhuren van jouw woning van je over. Ideaal wanneer je je
                  geen zorgen wilt maken over de lasten die kunnen komen kijken
                  bij het verhuren van je woning, appartement, studio of kamer.
                  Met dit pakket laat je de bezichtigingen, contact met de
                  (potentiële) huurders, sleuteloverdracht, styling, fotografie
                  en plaatsing op diverse kanalen aan ons over. Ook kunnen we je
                  natuurlijk advies op maat geven. Door dit beheerderspakket
                  houd jij gegarandeerd veel tijd over voor andere zaken.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div
              class="text-lg-right"
              style={{ textAlign: "center", margin: "32px 0 0" }}
            >
              <div class="icon-box">
                <i class="fas fa-key"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BeheerFeature;
