import React, { useState } from "react";
import { sendApplyMail } from "../../api/packages/Packages";

const Form = ({ pkg, extras }) => {
  const [input, setInput] = useState({});

  const [sent, setSent] = useState();
  const [sentCorrectly, setSentCorrectly] = useState();
  const [loading, setLoading] = useState();

  const sendMail = () => {
    setSent(false);
    setLoading(true);
    sendApplyMail(
      pkg,
      input.firstName,
      input.lastName,
      input.emailAddress,
      input.phoneNumber,
      input.text,
      extras
    )
      .then((res) => {
        if (res) {
          setSentCorrectly(true);
        }
        setSent(true);
        setLoading(false);
      })
      .catch((e) => {
        setSent(true);
        setLoading(false);
      });
  };

  if (sentCorrectly) {
    return (
      <div
        class="request-sent card pt-card col-sm-4"
        id="request-sent"
        style={{ height: "100%" }}
      >
        <h3 style={{ float: "left" }}>Aanvraag verstuurd!</h3>
      </div>
    );
  }

  return (
    <div class="card pt-card col-sm-4" style={{ height: "100%" }}>
      <h3 style={{ float: "left" }}>Woning verhuren?</h3>
      <b>
        <span style={{ textDecoration: "underline" }}>
          Vul dit formulier in
        </span>
      </b>
      <br />
      <form class="text-left">
        <input
          style={{
            border:
              sent && !input?.firstName ? "1px solid red" : "1px solid #e1e1e1",
          }}
          type="text"
          id="fname"
          name="fname"
          placeholder="Voornaam"
          value={input?.firstName}
          onChange={(e) => {
            e.persist();
            setInput((i) => ({ ...i, firstName: e.target.value }));
          }}
        />
        <input
          type="text"
          id="lname"
          name="lname"
          style={{
            border:
              sent && !input?.lastName ? "1px solid red" : "1px solid #e1e1e1",
          }}
          placeholder="Achternaam"
          value={input?.lastName}
          onChange={(e) => {
            e.persist();
            setInput((i) => ({ ...i, lastName: e.target.value }));
          }}
        />
        <input
          type="email"
          id="email"
          name="email"
          style={{
            border:
              sent && !input?.emailAddress
                ? "1px solid red"
                : "1px solid #e1e1e1",
          }}
          placeholder="Emailadres"
          value={input?.emailAddress}
          onChange={(e) => {
            e.persist();
            setInput((i) => ({ ...i, emailAddress: e.target.value }));
          }}
        />
        <input
          type="tel"
          id="tel"
          name="tel"
          placeholder="Telefoonnummer"
          style={{
            border:
              sent && !input?.phoneNumber
                ? "1px solid red"
                : "1px solid #e1e1e1",
          }}
          value={input?.phoneNumber}
          onChange={(e) => {
            e.persist();
            setInput((i) => ({ ...i, phoneNumber: e.target.value }));
          }}
        />
        <textarea
          type="text"
          id="text"
          name="text"
          placeholder="Bericht"
          value={input?.text}
          style={{
            border:
              sent && !input?.text ? "1px solid red" : "1px solid #e1e1e1",
          }}
          onChange={(e) => {
            e.persist();
            setInput((i) => ({ ...i, text: e.target.value }));
          }}
        />
        <br />
        <br />
        {!loading && (
          <div class="sub-btn2">
            <button
              style={{ textAlign: "center", width: "60%" }}
              class="button"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                sendMail();
              }}
            >
              Versturen
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default Form;
