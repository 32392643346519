import React from "react";
import { Link } from "react-router-dom";

export default () => {
  const asset = require("../../assets/images/ellipse-icon/Asset-4_2.png");

  return (
    <section id="circle-cta" className="spacing ellipse_icons--shape">
      <div className="ellipse_icons ellipse_icons-1">
        {/* <img
          src={asset}
          alt="bottom"
          style={{ top: "auto", bottom: 0, left: "350px", maxWidth: "150px" }}
        /> */}
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="circle-box text-center">
              <h3 className="text-white mb-0">
                Klaar om <br />
                jouw woning <br />
                te verhuren?
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
