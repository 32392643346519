import React, { useCallback, useContext, useEffect, useState } from "react";
import Questions from "../components/home/Questions";
import Team from "../components/about/Team";
import Hero from "../components/home/Hero";
import USPs from "../components/home/USPs";
import { getUSPs } from "../api/pages/Pages";
import { LanguageContext } from "../context/LanguageContext";
import { useLocation } from "react-router";

export default () => {
  const image = require("../assets/images/over-ons_hero--bg.jpg");
  const { language } = useContext(LanguageContext);
  const location = useLocation();

  const [usps, setUsps] = useState();

  const updateUsps = useCallback(async () => {
    const usps = await getUSPs(language);
    setUsps(usps?.slice(0, 3));
  }, [language]);

  useEffect(() => {
    window.scrollTo(0, 0);
    updateUsps();
  }, [updateUsps]);

  return (
    <div>
      <Hero
        linkText="Of laat ons je adviseren"
        title="Laten we iets over onszelf vertellen"
        link={
          location.pathname === "/beheer-over-ons"
            ? "/beheer-contact"
            : "contact"
        }
        image={image}
      />
      {location.pathname !== "/beheer-over-ons" && (
        <USPs
          backgroundColor="#FFFFFF"
          iconBackgroundColor="#28beef"
          iconColor="#FFFFFF"
          textColor="#000000"
          whiteIcons
          usps={usps}
        />
      )}
      {location.pathname === "/beheer-over-ons" && (
        <section className="spacing">
          <div className="container">
            <h3 className="text-center mb-5">
              Eerlijke prijs, hart voor de zaak, transparant contact
            </h3>
            <p style={{ textAlign: "start" }}>
              Wij staan voor persoonlijk contact en het aanbieden van een
              goedkopere prijs dan andere makelaars. Daarnaast betaal je ook
              echt alleen wat we zijn overeengekomen, zo is er altijd sprake van
              een eerlijke prijs. Je komt dus nooit voor onverwachte
              verrassingen staan. Je betaalt daarom zeker niet meer dan we samen
              hebben afgesproken!
              <br />
              <br />
              Wij van LTWonen hebben een hart voor de zaak. Wij zijn creatief en
              willen altijd met je meedenken. Jij bepaalt daarin zelf hoeveel je
              uit handen wilt geven.
              <br />
              <br />
              Ook transparant contact vinden wij erg belangrijk. Daarom houden
              wij jou van alles op de hoogte. Wij investeren in een goede en
              duidelijke communicatie met onze klanten. Zo weet iedereen, op elk
              moment, waar zij aan toe zijn.
            </p>
          </div>
        </section>
      )}
      <Team />
      <Questions green />
    </div>
  );
};
